<template>
  <div class="fixed inset-0 flex items-center justify-center z-50">
    <div class="fixed inset-0 bg-black opacity-50" @click.self="closeCalendarModel"></div>
    <div class="bg-white rounded-lg shadow-lg w-96 p-6 z-10">
      <h2 class="text-lg font-bold mb-4 text-center">Date Range Accumulated</h2>
      <div class="mb-4">
        <label for="start-date" class="block text-sm font-medium text-gray-700">Start Date:</label>
        <input
            type="date"
            id="start-date"
            v-model="startDate"
            class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
        />

        <label for="end-date" class="block text-sm font-medium text-gray-700">End Date:</label>
        <input
            type="date"
            id="end-date"
            v-model="endDate"
            class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
        />
      </div>
      <div class="flex justify-between mt-5">
        <button
            @click="closeCalendarModel"
            class="bg-gray-300 text-black font-bold py-2 px-4 rounded hover:bg-gray-400"
        >
          Cancel
        </button>
        <button
            @click="saveRange"
            class="bg-green-500 text-white font-bold py-2 px-4 rounded hover:bg-green-600"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'; // Ensure this is correctly imported

export default defineComponent({
  components: {
    FontAwesomeIcon,
  },
  data() {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1); // Set to yesterday's date
    return {
      startDate: yesterday.toISOString().split('T')[0], // Set to yesterday's date
      endDate: today.toISOString().split('T')[0], // Set to today's date
    };
  },
  methods: {
    closeCalendarModel() {
      this.$emit('close-model');
    },
    saveRange() {
      const selectedDateRange = {
        begin: new Date(this.startDate).toISOString().split(".")[0],
        end: new Date(this.endDate).toISOString().split(".")[0]
      };
      // Call the passed function with startDate and endDate
      this.$emit('save', selectedDateRange);
      this.closeCalendarModel();
    },
  },
});
</script>

<style scoped>
/* Additional styles can go here if needed */
</style>
