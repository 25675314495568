<template>
  <!-- sidebar -->
  <nav class="sidebar bg-white dark:bg-gray-800">
    <!-- sidebar head -->
    <div class="sidebar-head p-4">
      <router-link to="/" exact class="flex">
        <img
          class="w-8 mt-1"
          src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjAuMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9InN2ZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiCgkgdmlld0JveD0iMCAwIDExMy40IDExMy40IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxMTMuNCAxMTMuNDsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPgoJLnN0MHtmaWxsOiMzMThDQzg7fQoJLnN0MXtmaWxsOiMyQTg5Qzc7fQo8L3N0eWxlPgo8cGF0aCBjbGFzcz0ic3QwIiBkPSJNNy41LDkwLjkiLz4KPHBhdGggY2xhc3M9InN0MCIgZD0iTTUuNyw4OC45di02MEw1Ni44LDAuMmw1MC45LDI4Ljd2NjBsLTQ1LjIsMjMuOWwwLjMtMTJoMWwtMC4xLDJsMCwwbDAuMiw0LjRsMzguOC0yMS40di01NGwtNDYtMjYuNWwtNDYsMjYuNQoJdjU1bDM3LjcsMjAuNGwwLjMtNi40aDF2MTJsLTI5LTE2Ii8+CjxwYXRoIGNsYXNzPSJzdDEiIGQ9Ik01Ni41LDE1LjFsNi42LDkuMWMtMC4yLDAuNy0zLjUsNS0zLjUsNXYyOS45YzAsMC4yLDAuMSwwLjYsMC4zLDEuMWMwLjIsMC41LDAuNSwwLjgsMC43LDAuOQoJYzAuNSwwLjIsNC42LDEuOCw3LjktMC4yYzEuNC0wLjksMi4yLTIuMSwyLjYtMi45YzAuNi0xLjIsMS4xLTIuNSwxLjUtNGMxLjktNi41LDEuMi0xMS41LDAuMi0xNS4xaC0yLjFMNzAuNiwzNWw3LjgtNmgxLjJ2MTBoLTEKCXYxMGwtMSwxM0w1OS4yLDc1LjFsMC4xLDQuNmwzLjMsNC41TDU5LjQsODRsMC4xLDMuMmw0LjEsMy4xaC0zLjl2MjIuOWgtMWgtNGgtMnYtMjNoLTRsMy44LTIuN2wtMC4xLTMuM2gtMi43bDIuOC00LjR2LTQuNAoJTDM0LjYsNjEuMmwtMS0xMS4ybC0wLjItMTIuMmgtMS4ybDAtOS42aDEuMmw5LjMsN1YzOGgtMy4xYy0wLjgsMy0xLjUsOS40LDAsMTUuMmMwLjUsMS45LDEuMiwzLjYsMS45LDVjMC40LDAuNywxLjMsMi4xLDMuMSwzCgljMy4zLDEuNyw2LjYsMC4yLDcsMGMwLjItMC4xLDAuNi0wLjMsMS0wLjhjMC4zLTAuNCwwLjUtMC45LDAuNS0xLjFjLTAuMi0xMC4xLTAuMy0yMC4xLTAuNS0zMC4yTDUwLDIzLjhMNTYuNSwxNS4xeiIvPgo8cGF0aCBjbGFzcz0ic3QxIiBkPSJNNTUuNiwxNS4yIi8+CjxwYXRoIGNsYXNzPSJzdDEiIGQ9Ik00Nyw0NSIvPgo8L3N2Zz4K"
          alt="logo trident"
        />
        <h2
          class="text-4xl font-normal ml-3 mt-2 text-gray-800 dark:text"
          style="color: #318bc8"
          translate="no"
        >
          Trident<span class="text-primary"></span>
        </h2>
      </router-link>
      <button
        class="lg:hidden block dark:text-gray-400 float-right -mt-7"
        @click="$emit('sidebarToggle')"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          role="img"
          width="25px"
          height="25px"
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 32 32"
        >
          <path
            fill="currentColor"
            d="M7.219 5.781L5.78 7.22L14.563 16L5.78 24.781l1.44 1.439L16 17.437l8.781 8.782l1.438-1.438L17.437 16l8.782-8.781L24.78 5.78L16 14.563z"
          />
        </svg>
      </button>
    </div>
    <!-- sidebar list -->
    <div class="sidebar-list p-4 mt-4 divide-y dark:divide-gray-700">
      <div class="pb-5">
        <p class="font-medium text-gray-400 dark:text-gray-400">Menu</p>
        <div class="wrap-item mt-4 dark:text-gray-500">
          <div class="item">
            <router-link
              to="/"
              exact
              class="w-full flex text-left rounded-md box-border p-3 hover:bg-gray-200 dark:hover:bg-gray-700"
              @click="closeDevicesSubMenu"
            >
              <span class="mr-3 text-xl"
                ><Icon icon="mdi:monitor-dashboard"
              /></span>
              <span class="w-full"> Dashboard </span>
            </router-link>
          </div>
          <div class="item mt-3">
            <router-link
              to="/component/listdevices"
              exact
              class="w-full flex text-left rounded-md box-border p-3 hover:bg-gray-200 dark:hover:bg-gray-700"
              @click="closeDevicesSubMenu"
            >
              <span class="mr-3 text-xl"
                ><Icon icon="ic:baseline-devices"
              /></span>
              <span class="w-full"> Devices </span>
            </router-link>
          </div>
          <div class="item mt-3">
            <div class="item">
              <router-link
                to="/component/messages"
                exact
                class="w-full flex text-left rounded-md box-border p-3 hover:bg-gray-200 dark:hover:bg-gray-700"
                @click="closeDevicesSubMenu"
              >
                <span class="mr-3 text-xl"
                  ><Icon icon="mdi:monitor-dashboard"
                /></span>
                <span class="w-full"> Messages </span>
              </router-link>
            </div>
          </div>
          <div class="item mt-3" v-if="userType == 'operator'">
            <div class="item">
              <router-link
                to="/component/alerts"
                exact
                class="w-full flex text-left rounded-md box-border p-3 hover:bg-gray-200 dark:hover:bg-gray-700"
                @click="closeDevicesSubMenu"
              >
                <span class="mr-3 text-xl"
                  ><Icon icon="ion:analytics" /></span>
                <span class="w-full"> Analytics </span>
              </router-link>
            </div>
          </div>
        
          <div class="wrap-item mt-4 dark:text-gray-500" v-if="false">
            <!-- Device Manager -->
            <div class="item mt-3 main_menu">
              <div
                class="hover-bgcolor"
                :class="['w-full', 'flex', 'text-left', 'cursor-pointer', 'rounded-md', 'box-border', 'p-3', {'hover:bg-gray-200': !showDeviceSubMenu}, {'dark:hover:bg-gray-700': !showDeviceSubMenu}]"
                @click="toggleDeviceSubMenu"
              >
                <span class="mr-3 text-xl"><Icon icon="clarity:devices-solid" /></span>
                <span class="w-full"> Device Manager </span>
                <!-- Arrow icon for indicating dropdown -->
                <span class="ml-auto">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="img"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    class="fill-current"
                  >
                    <path d="M7 10l5 5 5-5z"></path>
                  </svg>
                </span>
              </div>
              <!-- Submenu for Device Manager -->
              <div v-if="false">
                <!-- Import Component -->
                <div class="item mt-3">
                  <div class="item">
                    <router-link
                      to="/component/ImportComponent"
                      exact
                      class="w-full flex text-left rounded-md box-border p-3 hover:bg-gray-200 dark:hover:bg-gray-700"
                    >
                      <span class="mr-3 text-xl"><Icon icon="clarity:import-outline-badged" /></span>
                      <span class="w-full"> Import Component </span>
                    </router-link>
                  </div>
                </div>
                <!-- Add Devices -->
                <div class="item mt-3">
                  <div class="item">
                    <router-link
                      to="/component/adddevices"
                      exact
                      class="w-full flex text-left rounded-md box-border p-3 hover:bg-gray-200 dark:hover:bg-gray-700"
                    >
                      <span class="mr-3 text-xl"><Icon icon="clarity:add-text-line" /></span>
                      <span class="w-full"> Add Devices </span>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="item mt-3">
            <div class="item">
              <router-link
                to="/component/settings"
                exact
                class="w-full flex text-left rounded-md box-border p-3 hover:bg-gray-200 dark:hover:bg-gray-700"
                @click="closeDevicesSubMenu"
              >
                <span class="mr-3 text-xl"
                  ><Icon icon="material-symbols:settings"
                /></span>
                <span class="w-full"> Settings </span>
              </router-link>
            </div>
          </div>
          <div class="item mt-3" v-if="false">
            <div class="item">
              <router-link
                to="/support"
                exact
                class="w-full flex text-left rounded-md box-border p-3 hover:bg-gray-200 dark:hover:bg-gray-700"
                @click="closeDevicesSubMenu"
              >
                <span class="mr-3 text-xl"
                  ><Icon icon="material-symbols:support-agent"
                /></span>
                <span class="w-full"> Support </span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { Icon } from "@iconify/vue";
import store from "@/store/store";
export default {
  computed: {
    userType() {
      return store.getters.getUser.type;
    },
  },
  components: {
    Icon,
  },
  data() {
    return {
      showDeviceSubMenu: false
    };
  },
  methods: {
    toggleDeviceSubMenu() {
      this.showDeviceSubMenu = !this.showDeviceSubMenu;
      this.$router.push('/component/ImportComponent')
    },
    closeDevicesSubMenu(){
      if(this.showDeviceSubMenu === true){
        this.showDeviceSubMenu = false
      }
    }
  }
};
</script>
<style scoped>
.hover-bgcolor{
  transition: all 300ms ease-in;
}
.hover-bgcolor:hover{
  background-color: #02FF00 !important;
  color: black !important;
}
</style>