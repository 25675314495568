<template>
  <div class="preloader">
    <div class="gear">
      <img src="@/assets/img/spinner.png" alt="Loading Gear" class="gear-img" />
    </div>
  </div>
</template>

<script>
export default {
  name: "PreLoader",
};
</script>

<style scoped>
.preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 118px; /* Ensures the loader is centered on the entire screen */
}

.gear {
  width: 80px;
  height: 80px;
  position: relative;
}

.gear-img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: center;
  animation: gear-spin 2s linear infinite;
}

@keyframes gear-spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
</style>
