import { createStore } from "vuex";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";
import gql from "graphql-tag";

const store = createStore({
  state() {
    return {
      user: {
        id: "",
        first_name: "",
        surname: "",
        email: "",
        type: "",
        enabled: false,
      },
      devices: [],
      rememberedEmail: null,
      rememberedEmailExpiration: null,
      deviceDetailsInfo: null,
    };
  },
  getters: getters,
  mutations: mutations,
  actions: actions,
});

//generate token mutation
export const graphqlMutations = {
  generateTokenMutation: gql`
    mutation auth($token: String!) {
      auth(token: $token) {
        accessToken
        refreshToken
      }
    }
  `,
  refreshToken: gql`
    mutation RefreshToken($token: String!) {
      refresh(refreshToken: $token) {
        token
      }
    }
  `,
};
//refresh token
export const refreshToken = gql`
  mutation RefreshToken($token: String) {
    refresh(refreshToken: $token) {
      token
    }
  }
`;
//getdevices qurey
export const getDevicesQueries = {
  getDevicesQuery: gql`
    query getDevices($userId: String!) {
      devices(filters: { userId: $userId }) {
        __typename
        ... on DevicesList {
          devices {
            id
            name
            soc
            soh
            batteryChemistry
            cycles
            gpsLat
            gpsLong
            lastSeen
            operatorId
            powerDispatched
            status
            userId
            temperature
            voltage
            insertedAt
            bms {
              id
              model
              soc
              soh
              temperature
              voltage
              cycles
            }
          }
        }
        __typename
        ... on AuthInfoField {
          message
        }
      }
    }
  `,
  getDevicesByOperatorQuery: gql`
    query getDevices($operatorId: String!) {
      devices(filters: { operatorId: $operatorId }) {
        __typename
        ... on DevicesList {
          devices {
            id
            name
            soc
            soh
            batteryChemistry
            cycles
            gpsLat
            gpsLong
            lastSeen
            operatorId
            powerDispatched
            status
            userId
            temperature
            voltage
            insertedAt
            bms {
              id
              model
              soc
              soh
              temperature
              voltage
              cycles
            }
          }
        }
        __typename
        ... on AuthInfoField {
          message
        }
      }
    }
  `,
  getDevicesByUserWithMessages: gql`
    query getDevices($userId: String!) {
      devices(filters: { userId: $userId }) {
        __typename
        ... on DevicesList {
          devices {
            id
            name
            soc
            soh
            batteryChemistry
            cycles
            gpsLat
            gpsLong
            lastSeen
            operatorId
            powerDispatched
            status
            userId
            temperature
            voltage
            insertedAt
            messages {
              edges {
                node {
                  id
                  deviceId
                  deviceName
                  messageText
                  messageId
                  receivedAt
                  sentAt
                  severity
                  messageType
                }
              }
            }
          }
        }
        __typename
        ... on AuthInfoField {
          message
        }
      }
    }
  `,
  getDevicesByOperatorWithMessages: gql`
    query getDevices($operatorId: String!) {
      devices(filters: { operatorId: $operatorId }) {
        __typename
        ... on DevicesList {
          devices {
            id
            name
            soc
            soh
            batteryChemistry
            cycles
            gpsLat
            gpsLong
            lastSeen
            operatorId
            powerDispatched
            status
            userId
            temperature
            voltage
            insertedAt
            messages {
              edges {
                node {
                  id
                  deviceId
                  deviceName
                  messageText
                  messageId
                  receivedAt
                  sentAt
                  severity
                  messageType
                }
              }
            }
          }
        }
        __typename
        ... on AuthInfoField {
          message
        }
      }
    }
  `,
};
//getdevices by name qurey
export const getDevicesByNameQueries = {
  getDevicesByNameQuery: gql`
    query getDeviceByName($id: String) {
      devices(filters: { id: $id }) {
        __typename
        ... on DevicesList {
          devices {
            __typename
            id
            meters {
              id
            }
          }
        }
        __typename
        ... on AuthInfoField {
          message
        }
      }
    }
  `,
};
export const getMetersByIdQuery = {
  getMetersData: gql`
    query MetersHistory($meterId: String) {
      metersHistory(filters: { meterId: $meterId }) {
        __typename
        ... on MeterHistoryList {
          meterHistory {
            __typename
            id
            meterId
            name
            model
            manufacturer
            version
            pwrIn
            pwrOut
            insertedAt
            updatedAt
            source
            inserted
            updated
            deleted
            synced
          }
        }
        ... on AuthInfoField {
          __typename
          message
        }
      }
    }
  `,
};
export const getBMSByIdQuery = {
  getBMSData: gql`
    query BmsHistory($bmsId: String) {
      BmsHistory(filters: { bmsId: $bmsId }) {
        __typename
        ... on MeterHistoryList {
          meterHistory {
            __typename
            id
            bmsId
            name
            model
            manufacturer
            version
            pwrIn
            pwrOut
            insertedAt
            updatedAt
            source
            inserted
            updated
            deleted
            synced
          }
        }
        ... on AuthInfoField {
          __typename
          message
        }
      }
    }
  `,
};
export const getDevicesByIdQuery = {
  getDevicesByIdQuery: gql`
    query getDeviceById($id: String!) {
      devices(filters: { id: $id }) {
        __typename
        ... on DevicesList {
          devices {
            id
            soc
            soh
            batteryChemistry
            cycles
            gpsLat
            gpsLong
            lastSeen
            name
            operatorId
            powerDispatched
            status
            temperature
            voltage
            insertedAt
            swUpdateTime
            softwareVersion
            bms {
              id
              model
              soc
              soh
              temperature
              voltage
              cycles
            }
            meters {
              pwrIn
              pwrOut
            }
          }
        }
        __typename
        ... on AuthInfoField {
          message
        }
      }
    }
  `,
};
export const getDevicesByStatusQuery = {
  getDevicesByStatusQuery: gql`
    query getDeviceByName($status: String) {
      devices(filters: { status: $status }) {
        __typename
        ... on DevicesList {
          devices {
            id
            soc
            soh
            batteryChemistry
            cycles
            gpsLat
            gpsLong
            lastSeen
            name
            operatorId
            powerDispatched
            status
            temperature
            voltage
            insertedAt
          }
        }
        __typename
        ... on AuthInfoField {
          message
        }
      }
    }
  `,
};

export const getUserQuery = {
  getUsersQuery: gql`
    query getUsers($email: String!) {
      users(filters: { email: $email }) {
        __typename
        ... on UsersList {
          users {
            id
            email
            enabled
            firstName
            surname
            type
            addresses {
              edges {
                node {
                  address
                  company
                  address
                  addressLine2
                  city
                  countyState
                  postcode
                  country
                  phone
                  vat
                }
              }
            }
          }
        }
        __typename
        ... on AuthInfoField {
          message
        }
      }
    }
  `,
};

export const getMessagesQuery = {
  getMessagesByDeviceIdAndTimeRange: gql`
    query GetMessages($deviceId: String, $begin: DateTime!, $end: DateTime!) {
      messages(
        filters: {
          receivedAtRange: { begin: $begin, end: $end }
          deviceId: $deviceId
        }
      ) {
        __typename
        ... on MessagesList {
          messages {
            messageText
            receivedAt
            sentAt
            severity
            insertedAt
          }
        }
        __typename
        ... on AuthInfoField {
          message
        }
      }
    }
  `,
  getLast24HoursMessages: gql`
    query GetMessages(
      $deviceId: String
      $messageType: String
      $begin: DateTime!
      $end: DateTime!
    ) {
      messages(
        filters: {
          receivedAtRange: { begin: $begin, end: $end }
          messageType: $messageType
          deviceId: $deviceId
        }
      ) {
        __typename
        ... on MessagesList {
          messages {
            messageText
            receivedAt
            sentAt
            severity
            insertedAt
          }
        }
        __typename
        ... on AuthInfoField {
          message
        }
      }
    }
  `,
  getPaginatedMessagesFiltered: gql`
    query GetMessages(
      $deviceId: String
      $messageType: String
      $first: Int
      $offset: Int
    ) {
      messages(
        first: $first
        offset: $offset
        filters: { messageType: $messageType, deviceId: $deviceId }
      ) {
        __typename
        ... on MessagesList {
          messages {
            messageText
            receivedAt
            sentAt
            severity
            messageType
            deviceName
            messageId
            devices {
              name
            }
          }
        }
        __typename
        ... on AuthInfoField {
          message
        }
      }
    }
  `,
  getPaginatedMessagesFilteredAllTypes: gql`
    query GetMessages($deviceId: String, $first: Int, $offset: Int) {
      messages(
        first: $first
        offset: $offset
        filters: { deviceId: $deviceId }
      ) {
        __typename
        ... on MessagesList {
          messages {
            messageText
            receivedAt
            sentAt
            severity
            messageType
            deviceName
            messageId
            devices {
              name
            }
          }
        }
        __typename
        ... on AuthInfoField {
          message
        }
      }
    }
  `,
  getPaginatedMessagesByUser: gql`
    query GetMessages($userId: String, $userType: String) {
      messages(userId: $userId, userType: $userType) {
        __typename
        ... on MessagesList {
          messages {
            id
            deviceId
            deviceName
            messageText
            messageId
            receivedAt
            sentAt
            severity
            source
            insertedAt
            updatedAt
          }
        }
        __typename
        ... on AuthInfoField {
          message
        }
      }
    }
  `,
  getLatestMessage: gql`
    query GetMessages($deviceId: String, $messageType: String) {
      messages(
        first: 1
        offset: 0
        filters: { messageType: $messageType, deviceId: $deviceId }
      ) {
        __typename
        ... on MessagesList {
          messages {
            messageText
            receivedAt
            sentAt
            severity
            messageType
            deviceName
            messageId
            devices {
              name
            }
          }
        }
        __typename
        ... on AuthInfoField {
          message
        }
      }
    }
  `,
  getBatteryPackMessages: gql`
    query BatteryPacksMessages($deviceId: UUID) {
      batteryPacksMessages(deviceId: $deviceId) {
        __typename
        ... on MessagesList {
          messages {
            messageText
            receivedAt
            sentAt
            severity
            messageType
            deviceName
            messageId
            devices {
              name
            }
          }
        }
        __typename
        ... on AuthInfoField {
          message
        }
      }
    }
  `,
  getMessagesFiltered: gql`
    query GetMessages($deviceId: String, $messageType: String) {
      messages(filters: { messageType: $messageType, deviceId: $deviceId }) {
        __typename
        ... on MessagesList {
          messages {
            messageText
            receivedAt
            sentAt
            severity
            messageType
            deviceName
            messageId
            devices {
              name
            }
          }
        }
        __typename
        ... on AuthInfoField {
          message
        }
      }
    }
  `,
  getMessagesLength: gql`
    query GetMessagesLength($deviceId: String, $messageType: String) {
      messagesLength(
        filters: { messageType: $messageType, deviceId: $deviceId }
      ) {
        __typename
        ... on MessagesLengthObject {
          length
        }
        __typename
        ... on AuthInfoField {
          message
        }
      }
    }
  `,
};

export default store;
