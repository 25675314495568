import { createRouter, createWebHistory } from "vue-router";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";

// Default Pages
import Dashboard from "../views/Dashboard.vue";
import ListDevices from "../views/ListDevices.vue";
import AddDevices from "../views/AddDevices.vue";
import DeviceDetails from "../views/DeviceDetails.vue";
import Message from "../views/Message.vue";
import Support from "../views/Support.vue";
import Settings from "../views/Settings.vue";
import Terms from "../views/layouts/auth/Terms.vue";
import ImportComponent from "../views/ImportComponent.vue";
import Alerts from "../views/Alert.vue";
import DeviceManager from "../views/DeviceManager.vue";

import Login from "../views/layouts/auth/Login.vue";
import Register from "../views/layouts/auth/Register.vue";
import ForgotPassword from "../views/layouts/auth/forgot-password.vue";

// error page
import Page404 from "../views/layouts/error/404.vue";
import Page500 from "../views/layouts/error/500.vue";
import PageMaintenance from "../views/layouts/error/maintenance.vue";

import store, { getUserQuery, graphqlMutations } from "@/store/store";

import checkToken from "@/jwt";
import apolloClient, { apolloProvider } from "@/jwt/apolloclientprovider";
import gql from "graphql-tag";
var appname = " - Trident App";

async function generateToken(user, to, next) {
  if (JSON.parse(sessionStorage.getItem("apollo-token"))) {
    await getUserFromDB(
      router,
      apolloProvider.defaultClient,
      user.email,
      to,
      next
    );
  } else {
    user.getIdToken(true).then(async (fbToken) => {
      try {
        const { data } = await apolloClient.mutate({
          mutation: graphqlMutations.generateTokenMutation,
          variables: {
            token: fbToken,
          },
        });
        const token = JSON.stringify(data.auth.accessToken);
        const refreshToken = JSON.stringify(data.auth.refreshToken);
        await sessionStorage.setItem("apollo-token", token);
        await sessionStorage.setItem("refreshToken", refreshToken);
        // Refresh the token before querying for user data
        // refreshTokenAndQueryUser(refreshToken, user.email, to, next);
        await getUserFromDB(
          router,
          apolloProvider.defaultClient,
          user.email,
          to,
          next
        );
      } catch (error) {
        // Error
        console.error("Error in GenerateToken mutation => ", error);
      }
    });
  }
}

async function getUserFromDB($router, $apollo, email, to, next) {
  await checkToken($apollo, $router);
  apolloClient
    .query({
      query: getUserQuery.getUsersQuery,
      variables: {
        email: email,
      },
    })
    .then(async (data) => {
      if (
        data &&
        data.data &&
        data.data.users &&
        data.data.users.users &&
        data.data.users.users.length > 0
      ) {
        let user = {
          id: data.data.users.users[0].id,
          first_name: data.data.users.users[0].firstName,
          surname: data.data.users.users[0].surname,
          type: data.data.users.users[0].type,
          enabled: data.data.users.users[0].enabled,
          address: data?.data?.users?.users?.[0]?.addresses?.edges?.[0]?.node,
          email: data.data.users.users[0].email
        };
        await store.dispatch("setUser", user);

        if (user.enabled) {
          if (redirectWrongUserType(store.getters.getUser.type, to.name)) {
            next({ path: "/" });
          } else {
            if (
              to.path == "/auth/login" ||
              to.path == "/auth/register" ||
              to.path == "/auth/forgot-password"
            ) {
              next({ path: "/" });
            } else {
              next();
            }
          }
        } else {
          logout();
          next({ path: "/auth/login" });
        }
      } else if (data.data.users.users.length === 0) {
        const userInfo = firebase.auth().currentUser;
        // Check the login method
        if (userInfo && userInfo.providerData.length > 0) {
          const providerId = userInfo.providerData[0].providerId;
          if (providerId === "google.com") {
            let variables = {
              email: userInfo.email,
              enabled: "true",
              firstName: userInfo.displayName,
              surname: userInfo.displayName,
              type: "user",
            };
            apolloClient
              .mutate({
                // Query
                mutation: gql`
                  mutation AddUser(
                    $email: String!
                    $enabled: Boolean!
                    $firstName: String!
                    $surname: String!
                    $type: String!
                  ) {
                    addUser(
                      email: $email
                      enabled: $enabled
                      firstName: $firstName
                      surname: $surname
                      type: $type
                    ) {
                      response {
                        ... on ResponseField {
                          message
                          ok
                        }
                        ... on AuthInfoField {
                          message
                        }
                      }
                    }
                  }
                `,
                variables: variables,
              })
              .then(async (data) => {})
              .catch((error) => {
                // Error
                console.error("Error in GenerateToken mutation => ", error);
              });
          } else {
            console.error("Provider is not Valid");
            logout();
          }
        }
      } else {
        // Handle case where user data is not found
        console.error("User data not found");
        logout();
      }
    })
    .catch((error) => {
      // Error
      console.error("Error in router getUsers query => ", error);
    });
}

const routes = [
  // Routes
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    meta: { title: "Dashboard " + appname, hideFooter: true },
  },
  {
    path: "/component/listdevices",
    name: "ListDevices",
    component: ListDevices,
    meta: { title: "List Devices " + appname, hideFooter: true },
  },
  {
    path: "/component/adddevices",
    name: "AddDevices",
    component: AddDevices,
    meta: { title: "Add devices " + appname, hideFooter: true },
  },
  {
    path: "/component/messages",
    name: "Message",
    component: Message,
    meta: { title: "Message " + appname, hideFooter: true },
  },
  {
    path: "/component/DeviceManager",
    name: "DeviceManager",
    component: DeviceManager,
    meta: { title: "DeviceManager " + appname, hideFooter: true },
  },
  {
    path: "/component/ImportComponent",
    name: "ImportComponent",
    component: ImportComponent,
    meta: { title: "ImportComponent " + appname, hideFooter: true },
  },
  {
    path: "/component/settings",
    name: "Settings",
    component: Settings,
    meta: { title: "Settings " + appname, hideFooter: true },
  },
  {
    path: "/component/DeviceDetails/:id",
    name: "DeviceDetails",
    component: DeviceDetails,
    props: true,
    meta: { title: "Device Details " + appname, hideFooter: true },
  },

  {
    path: "/component/alerts",
    name: "Alerts",
    component: Alerts,
    meta: { title: "Alerts " + appname, hideFooter: true },
  },

  {
    path: "/support",
    name: "Support",
    component: Support,
    meta: { title: "Support " + appname },
  },
  // layouts
  {
    path: "/auth/login",
    name: "Login",
    component: Login,
    meta: { title: "Login" + appname, hideNav: true, hideFooter: false },
  },
  {
    path: "/auth/register",
    name: "Register",
    component: Register,
    meta: { title: "Register" + appname, hideNav: true, hideFooter: false },
  },
  {
    path: "/auth/terms",
    name: "Terms",
    component: Terms,
    meta: { title: "Terms" + appname, hideNav: true, hideFooter: true },
  },
  {
    path: "/auth/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
    meta: {
      title: "i Forgot Password" + appname,
      hideNav: true,
      hideFooter: true,
    },
  },
  // layout/error
  // default page 404
  {
    path: "/:pathMatch(.*)*",
    name: "Page404",
    component: Page404,
    meta: { title: "Upps! 404" + appname, hideNav: true },
  },
  {
    path: "/500",
    name: "Page500",
    component: Page500,
    meta: { title: "Server internal Error" + appname, hideNav: true },
  },
  {
    path: "/maintenance",
    name: "maintenance",
    component: PageMaintenance,
    meta: {
      title: "Sorry the app has been Maintenance" + appname,
      hideNav: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,

  linkExactActiveClass: "exact-active",
});

const whiteList = [
  "/auth/login",
  "/auth/register",
  "/auth/terms",
  "/auth/forgot-password",
  "/:pathMatch(.*)*",
  "/500",
];

const userRoutes = [
  "Dashboard",
  "ListDevices",
  "Message",
  "Support",
  "Settings",
];

const operatorRoutes = [
  "Dashboard",
  "ListDevices",
  "AddDevices",
  "Message",
  "DeviceManager",
  "ImportComponent",
  "Settings",
  "DeviceDetails",
  "Alerts",
  "Support",
];

let preventReload = false;

/*** inactivity Code **/
// Define an array of routes that should be excluded from inactivity detection
const excludedRoutes = [
  "maintenance",
  "Page500",
  "Page404",
  "ForgotPassword",
  "Terms",
  "Register",
  "Login",
];

// Define the inactivity logout time (e.g., 1 hour)
// 3600000 milliseconds = 1 hour
const INACTIVITY_TIMEOUT = 1 * 60 * 60 * 1000; // 1 h in milliseconds

let inactivityTimer;

// Function to handle user inactivity
function handleInactivity() {
  clearTimeout(inactivityTimer);
  logout(router);
  router.push({ path: "/auth/login" }); // Redirect to login page
}

// Reset the inactivity timer whenever there is user activity
function resetInactivityTimer() {
  clearTimeout(inactivityTimer);
  inactivityTimer = setTimeout(handleInactivity, INACTIVITY_TIMEOUT);
}

// Function to handle any user activity (mouse movement, keyboard input, etc.)
function handleUserActivity() {
  resetInactivityTimer();
}
/*** END inactivity Code **/

router.beforeEach((to, from, next) => {
  firebase.auth().onAuthStateChanged(function (user) {
    if (excludedRoutes.includes(to.name)) {
      // Remove event listeners for mousemove and keypress
      document.removeEventListener("mousemove", handleUserActivity);
      document.removeEventListener("keypress", handleUserActivity);
    } else {
      // Add event listeners for mousemove and keypress
      document.addEventListener("mousemove", handleUserActivity);
      document.addEventListener("keypress", handleUserActivity);
    }

    if (!user) {
      if (whiteList.indexOf(to.path) !== -1) {
        // Perform inactivity handling
        resetInactivityTimer();
        next();
      } else {
        next({ path: "/auth/login" });
      }
    } else {
      const userInfo = firebase.auth().currentUser;
      // Check if the current Firebase user matches the user stored in the Vuex store
      if (userInfo && store.getters.getUser.email === userInfo.email) {
        resetInactivityTimer();
        next(); // Allow navigation to continue without redirection
      } else {
        if (store.getters.getUser.email === "") {
          if (user.emailVerified) {
            generateToken(user, to, next);
          }
        } else {
          if (store.getters.getUser.enabled) {
            if (redirectWrongUserType(store.getters.getUser.type, to.name)) {
              // Perform inactivity handling
              resetInactivityTimer();
              next();
            } else {
              if (
                to.path === "/auth/login" ||
                to.path === "/auth/register" ||
                to.path === "/auth/forgot-password"
              ) {
                next({ path: "/" });
              } else {
                // Perform inactivity handling
                resetInactivityTimer();
                next();
              }
            }
          } else {
            logout();
            next({ path: "/auth/login" });
          }
        }
      }
    }
  });

  document.title = to.meta.title;
});
function logout() {
  firebase
    .auth()
    .signOut()
    .then(() => {
      const user = {
        email: "",
        first_name: "",
        surname: "",
        type: "",
      };
      sessionStorage.clear();
      store.dispatch("setUser", user);
    })
    .catch((error) => {
      alert("Error while logout", error);
    });
}

function redirectWrongUserType(type, path) {
  if (type == "operator") {
    if (!operatorRoutes.includes(path)) {
      return true;
    } else {
      return false;
    }
  } else {
    if (!userRoutes.includes(path)) {
      return true;
    } else {
      return false;
    }
  }
}

export default router;
