<template>
  <div class="w-full h-screen">
    <div class="flex shadow rounded-md h-screen">
      <div class="bg-white dark:bg-gray-900 w-full">
        <form @submit.prevent="login">
          <div class="form-body lg:max-w-xl mx-auto lg:p-10 space-y-6">
            <div class="form-head cursor-pointer" @click="$router.push('/')">
              <span
                class="dark:text-white font-semibold text-gray-800 text-4xl flex"
              >
                <img src="../../../assets/logo/logo.svg" alt="" class="w-10" />
                <span class="trident_title">Trident</span>
              </span>
            </div>
            <div class="space-y-3">
              <h2 class="dark:text-white font-semibold text-gray-800 text-4xl">
                Welcome, to Trident<span>.</span>
              </h2>
              <p class="dark:text-gray-400 text-gray-700">
                Please enter your account to continue.
              </p>
            </div>
            <button
              type="button"
              class="dark:text-white text-gray-700 flex justify-center gap-2 dark:bg-gray-700 bg-gray-100 hover:bg-gray-100/50 p-2 w-full rounded-md"
              @click="googleLogin"
            >
              <img
                class="w-8"
                src="../../../assets/logo/google-logo.svg"
                alt=""
              />
              <p class="mt-1 dark:text-white text-gray-700">
                Sign in with Google
              </p>
            </button>
            <span class="flex items-center justify-center space-x-2">
              <span class="h-px dark:bg-gray-600 bg-gray-200 w-full"></span>
              <span class="font-normal text-gray-500">or </span>
              <span class="h-px dark:bg-gray-600 bg-gray-200 w-full"></span>
            </span>
            <div v-if="showUserDisabledMessage">
              <notifications position="top" group="loginError" />
              {{ this.errorMessage }}
            </div>
            <div class="space-y-5">
              <div class="relative z-0 w-full mb-6 group">
                <input
                  type="email"
                  v-model="email"
                  name="floating_email"
                  id="floating_email"
                  class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-primary focus:outline-none focus:ring-0 focus:border-primary peer"
                  placeholder=" "
                  required
                />
                <label
                  for="floating_email"
                  class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-focus:dark:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >Email address</label
                >
              </div>
              <div class="relative z-0 w-full mb-6 group">
                <input
                  type="password"
                  v-model="password"
                  name="floating_password"
                  id="floating_password"
                  class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-primary focus:outline-none focus:ring-0 focus:border-primary peer"
                  placeholder=" "
                  required
                />
                <label
                  for="floating_password"
                  class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-focus:dark:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >Password</label
                >
              </div>
            </div>
            <div class="flex justify-between">
              <div class="flex items-start">
                <div class="flex items-center h-5">
                  <input
                    id="remember"
                    type="checkbox"
                    v-model="rememberMe"
                    value=""
                    autocomplete="off"
                    class="accent-primary focus:ring-4 cursor-pointer w-4 h-4 border border-gray-300 rounded dark:bg-gray-700 bg-gray-50 focus:ring-3 focus:ring-primary/30"
                  />
                </div>
                <label
                  for="remember"
                  class="ml-2 text-sm cursor-pointer font-normal dark:text-white text-gray-500"
                  >Remember for 30 days</label
                >
              </div>
              <button
                @click="$router.push('/auth/forgot-password')"
                class="text-sm dark:text-white hover:text-primary text-gray-700"
              >
                Forgot password?
              </button>
            </div>

            <button
              class="text-white bg-primary hover:bg-primary/80 p-3 w-full rounded-md"
            >
              Login, to continue
            </button>
            <p class="dark:text-white text-center text-gray-700">
              Don't have an account?
              <button
                type="button"
                @click="$router.push('/auth/register')"
                class="ml-2 text-primary"
              >
                Register here
              </button>
            </p>
          </div>
        </form>
      </div>
      <div class="bg-image">
        <img
          class="w-full h-full"
          src="../../../assets/img/Trident_Homepage.jpeg"
        />
      </div>
    </div>
  </div>
</template>
<script>
import store, {
  graphqlMutations,
  getDevicesQueries,
  getUserQuery,
} from "@/store/store";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import renewTokenIfNeeded from "@/jwt";
import logout from "@/jwt/auth";
import checkToken from "@/jwt";
import getDevices from "@/api/data";

export default {
  data() {
    return {
      showUserDisabledMessage: false,
      errorMessage: "",
      email: store.getters.getRememberedEmail || "",
      rememberMe: false,
    };
  },
  methods: {
    async generateToken(token) {
      this.$apollo
        .mutate({
          // Query
          mutation: graphqlMutations.generateTokenMutation,
          variables: {
            token: token,
          },
        })
        .then(async (data) => {
          const token = JSON.stringify(data.data.auth.accessToken);
          const refreshToken = JSON.stringify(data.data.auth.refreshToken);
          sessionStorage.setItem("apollo-token", token);
          sessionStorage.setItem("refreshToken", refreshToken);
          await this.getUser(this.email);
        })
        .catch((error) => {
          if (error.networkError) {
            toast.error(
              "Server Error - Unable to establish connection please try again, if the issue persist please contact support.",
              {
                position: toast.POSITION.TOP_CENTER,
                theme: "colored",
              }
            );
          }
          // Error
          console.error("Error in GenerateToken mutation => ", error);
        });
    },
    async getUser(email) {
      await checkToken(this.$apollo, this.$router);
      this.$apollo
        .query({
          query: getUserQuery.getUsersQuery,
          variables: {
            email: email,
          },
        })
        .then(async (data) => {
          if (
            data &&
            data.data &&
            data.data.users &&
            data.data.users.users &&
            data.data.users.users.length > 0
          ) {
            let user = {
              id: data.data.users.users[0].id,
              first_name: data.data.users.users[0].firstName,
              surname: data.data.users.users[0].surname,
              type: data.data.users.users[0].type,
              enabled: data.data.users.users[0].enabled,
              email: this.email
            };
            await store.dispatch("setUser", user);

            if (this.rememberMe) {
              const expiration = new Date();
              expiration.setDate(expiration.getDate() + 30); // 30 days from now
              await store.dispatch("setRememberedEmail", {
                email: this.email,
                expiration: expiration.getTime(),
              });
            }
            if (user.enabled) {
              const devicesData = await getDevices(
                this.$apollo,
                this.$router,
                user.id,
                user.type
              );
              await store.dispatch("setDevices", devicesData);

              await this.$router.push("/");
            } else {
              logout(this.$router);
              this.showUserDisabledMessage = true;
              this.$notify({
                group: "loginError",
                type: "error",
                title: "Login failed",
                text: "Your account is disabled, you need to ask an administrator to enable it for you.",
              });
            }
          } else {
            // Handle case where user data is not found
            console.error("User data not found");
            logout(this.$router);
          }
        })
        .catch((error) => {
          // Error
          console.error("Error in login getUsers query => ", error);
        });
    },
    async login() {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(async (firebaseUser) => {
          if (firebaseUser.user.emailVerified) {
            firebaseUser.user.getIdToken(true).then(async (token) => {
              await this.generateToken(token);
            });
          } else {
            logout(this.$router);
            this.showUserDisabledMessage = true;
            this.$notify({
              group: "loginError",
              type: "error",
              title: "Login failed",
              text: "Your email account is not verified, you need to click the verification link received in the provided email",
            });
          }
        })
        .catch((error) => {
          console.log("ERROR MESSAGEE ON LOGIN => ", error)
          let errorMessage = "";
          if (error.message.includes("(auth/wrong-password)")) {
            errorMessage =
              "The username or password you entered is incorrect. Please try again.";
          }
          logout(this.$router);
          alert(errorMessage); // Change it to a modal window
        });
    },
    googleLogin() {
      let provider = new firebase.auth.GoogleAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then(async (result) => {
          result.user.getIdToken(true).then(async (token) => {
            this.email = result.user.email;
            await this.generateToken(token);
          });
        })
        .catch((error) => {
          alert(error.message); // Change it to a modal window
          // Log specific error information
          if (error.code === "auth/popup-closed-by-user") {
            console.error("The Google sign-in popup was closed by the user.");
          } else if (error.code === "auth/cancelled-popup-request") {
            console.error("The Google sign-in popup request was cancelled.");
          } else {
            console.error(
              "An unknown error occurred during Google sign-in:",
              error
            );
          }
        });
    },
  },
  mounted() {
    // Retrieve remembered email from local storage on component mount
    const rememberedEmail = localStorage.getItem("rememberedEmail");
    if (rememberedEmail) {
      this.email = rememberedEmail;
    }
  },
};
</script>

<style>
/* custom pattern https://superdesigner.co/tools/css-backgrounds */
.vue-notification {
  font-size: 30px;
}

.waves {
  background: radial-gradient(
      circle at top left,
      transparent 25%,
      #ffffff 25.5%,
      #ffffff 36%,
      transparent 37%,
      transparent 100%
    ),
    radial-gradient(
      circle at bottom right,
      transparent 34%,
      #ffffff 34.5%,
      #ffffff 45.5%,
      transparent 46%,
      transparent 100%
    );
  background-size: 6em 6em;
  opacity: 1;
}

.trident_title {
  color: #318bc8;
  margin-left: 0.3em;
}

.ml-2.text-primary {
  color: rgb(0, 255, 0);
}

.text-white.bg-primary.p-3.w-full.rounded-md {
  background: rgb(0, 255, 0);
}

.bg-image {
  float: right !important;
  width: 70%;
  position: relative;
  height: auto;
  margin-right: -0% !important;
}

.split {
  height: 100%;
  width: 60%;
  position: fixed;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
  padding-top: 20px;
  left: 0;
}
</style>
