<template>
  <div>
    <div class="flex items-center justify-between mt-2 w-full">
      <h1
        class="text-2xl text-gray-900 dark:text-gray-200 font-medium py-3 mr-4 mt-4 px-4 min-w-[150px]"
      >
        Settings
      </h1>
    </div>
    <div
      class="card bg-white dark:bg-gray-800 rounded-md border dark:border-gray-700 mt-6 mb-6 mx-6 px-4"
    >
      <div>
        <nav class="flex flex-row space-x-4 my-4">
          <button
            @click="tab = 'profile'"
            :class="{
              'text-[#318bc8] border-b-2 border-r-6 border-[#318bc8]':
                tab === 'profile',
              'text-white hover:text-[#318bc8]': tab !== 'profile',
            }"
            class="transition-colors duration-300 ease-in-out text-white hover:text-[#318bc8]"
          >
            Profile
          </button>
          <button
            @click="tab = 'address'"
            :class="{
              'text-[#318bc8] border-b-2 border-r-6 border-[#318bc8]':
                tab === 'address',
              'text-white hover:text-[#318bc8]': tab !== 'address',
            }"
            class="transition-colors duration-300 ease-in-out text-white hover:text-[#318bc8]"
          >
            Contact Information
          </button>
          <button
            hidden
            @click="tab = 'billing'"
            :class="{
              'text-[#318bc8] border-b-2 border-r-6 border-[#318bc8]':
                tab === 'billing',
              'text-white hover:text-[#318bc8]': tab !== 'billing',
            }"
            class="transition-colors duration-300 ease-in-out text-white hover:text-[#318bc8]"
          >
            Usage Billing
          </button>
          <button
            hidden
            @click="tab = 'payment'"
            :class="{
              'text-[#318bc8] border-b-2 border-r-6 border-[#318bc8]':
                tab === 'payment',
              'text-white hover:text-[#318bc8]': tab !== 'payment',
            }"
            class="transition-colors duration-300 ease-in-out text-white hover:text-[#318bc8]"
          >
            Payment
          </button>
          <button
            hidden
            @click="tab = 'operator'"
            :class="{
              'text-[#318bc8] border-b-2 border-r-6 border-[#318bc8]':
                tab === 'operator',
              'text-white hover:text-[#318bc8]': tab !== 'operator',
            }"
            class="transition-colors duration-300 ease-in-out text-white hover:text-[#318bc8]"
          >
            Operator
          </button>
        </nav>
        <hr />
      </div>
      <div>
        <ProfileComponent v-if="tab === 'profile'" />
        <AddressComponent v-if="tab === 'address'" />
        <BillingComponent v-if="tab === 'billing'" />
        <PaymentComponent v-if="tab === 'payment'" />
        <OperatorComponent v-if="tab === 'operator'" />
      </div>
    </div>
  </div>
</template>

<script>
import ProfileComponent from "./componentsSettings/Profile.vue";
import AddressComponent from "./componentsSettings/Address.vue";
import BillingComponent from "./componentsSettings/Billing.vue";
import PaymentComponent from "./componentsSettings/Payment.vue";
import OperatorComponent from "./componentsSettings/Operator.vue";
export default {
  data() {
    return {
      tab: "profile",
    };
  },
  components: {
    ProfileComponent,
    AddressComponent,
    BillingComponent,
    PaymentComponent,
    OperatorComponent,
  },
};
</script>

<style></style>
