<template>
  <Preloader v-if="loading" />

  <div>
    <div class="flex items-center justify-between mt-2 w-full">
      <h1 class="text-2xl text-gray-900 dark:text-gray-200 font-medium py-3 mr-4 mt-4 px-4 min-w-[150px]">
        List Devices
      </h1>
    </div>

    <div class="card bg-white dark:bg-gray-800 rounded-md p-5 border dark:border-gray-700 flex mt-6 mb-6 mx-6 px-4">
      <div class="flex flex-col w-full">
        <h2 class="mb-4" style="color: white">List Devices</h2>
        <DataTable :value="dataDevices" :rowStyle="rowStyle" :rowClass="rowClasses" tableStyle="min-width: 50rem">
          <Column field="name" header="Name">
            <template #body="slotProps">
              <router-link
                :to="`/component/DeviceDetails/${slotProps.data.id}`"
                class="text-blue-400 underline cursor-pointer"
              >
                {{ slotProps.data.name }}
              </router-link>
            </template>
          </Column>
          <Column field="id" header="Device_id"></Column>
          <Column field="status" header="Status" class="column-status">
            <template #body="slotProps">
              {{ slotProps.data.lastSeenStatus }}
            </template>
          </Column>
          <Column field="health" header="State of Health" class="column-health">
            <template #body="slotProps">
              {{ slotProps.data.bms.soh }}
            </template>
          </Column>
          <Column field="charge" header="State of Charge">
            <template #body="slotProps">
              {{ slotProps.data.bms.soc }}
            </template>
          </Column>
          <Column field="last_seen" header="Last Seen">
            <template #header>
              <Icon
                  :icon="sortOrder === 'asc' ? 'mdi:arrow-up' : 'mdi:arrow-down'"
                  @click="sortDevices"
                  class="cursor-pointer ml-2"
              />
            </template>

            <template #body="slotProps">
              {{ new Date(slotProps.data.lastSeen).toLocaleString() }}
            </template>
          </Column>
          <Column field="gpsLat" header="GPS Coordinates">
            <template #body="slotProps">
              {{ slotProps.data.gpsLong }} -
              {{ slotProps.data.gpsLat }}
            </template>
          </Column>

          <!-- Show message in the middle if no devices -->
          <template v-if="dataDevices.length === 0">
            <!-- Show message in the middle if no devices -->
            <div v-if="dataDevices.length === 0" class="empty-message" style="
                position: absolute;
                top: 85%;
                left: 50%;
                transform: translate(-50%, -85%);
              ">
              <p class="text-gray-500 dark:text-gray-400">
                No devices available.
              </p>
            </div>
          </template>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import store, { getMessagesQuery } from "@/store/store";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import checkToken from "@/jwt";
import getDevices from "@/api/data";
import Preloader from "@/components/Preloader.vue";
import {Icon} from "@iconify/vue";

export default {
  components: {
    Icon,
    Preloader,
    DataTable,
    Column,
  },
  data() {
    return {
      loading: true,
      dataDevices: [],
      sortOrder: 'asc', // Initial sort order
      updateDevicesInterval: ""
    };
  },
  methods: {
    sortDevices() {
      // Toggle the sort order
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';

      // Sort data based on the current sort order
      if (this.sortOrder === 'asc') {
        this.dataDevices.sort((a, b) => new Date(a.lastSeen) - new Date(b.lastSeen));
      } else {
        this.dataDevices.sort((a, b) => new Date(b.lastSeen) - new Date(a.lastSeen));
      }
    },

    pushDevice(device, heartbeat, bms) {

      let extendedDevice = {
        ...device,
        lastSeenStatus: "",
        lastSoc: "",
        lastSoh: "",
        batteryCycles: ""
      }



      if (heartbeat?.data?.messages?.messages) {
        let lastMessage = heartbeat.data.messages.messages[0]
        let nowDate = new Date()
        let deviceLastSeenDate = new Date(device.lastSeen)
        let lastHeartbeatDate = new Date(lastMessage.receivedAt)
        let lastSeenTimestamp;
        if (deviceLastSeenDate.getTime() > lastHeartbeatDate.getTime()) {
          lastSeenTimestamp = deviceLastSeenDate.getTime()
        } else if (lastHeartbeatDate.getTime() > deviceLastSeenDate.getTime()) {
          lastSeenTimestamp = lastHeartbeatDate.getTime()
        }

        if (lastSeenTimestamp < (nowDate.getTime() - 660000)) {
          extendedDevice.lastSeenStatus = "Offline"
        } else {
          extendedDevice.lastSeenStatus = "Online"
        }
      }


      if (bms?.data?.messages?.messages) {
        let lastMessage = bms.data.messages.messages[0]
        let parsedMessage = JSON.parse(lastMessage.messageText)

        if (parsedMessage.battery_soc.toString().includes("%")) {
          extendedDevice.lastSoc = parsedMessage.battery_soc.replace("%", "")
        } else {
          extendedDevice.lastSoc = parsedMessage.battery_soc
        }

        if (parsedMessage.battery_soh.toString().includes("%")) {
          extendedDevice.lastSoh = parsedMessage.battery_soh.replace("%", "")
        } else {
          extendedDevice.lastSoh = parsedMessage.battery_soh
        }

        extendedDevice.batteryCycles = parsedMessage.battery_cycle;
      }

      if (["online", "offline"].includes(extendedDevice.lastSeenStatus.toLowerCase())) {
        this.dataDevices.push(extendedDevice)
      }
    },
    rowStyle(device, index) {
      return {
        "background-color": index % 2 === 0 ? "#ffffff" : "#f0f0f0",
      };
    },
    async updateDevices(apollo, router, userId, userType) {
      this.dataDevices = await getDevices(apollo, router, userId, userType);

      this.dataDevices.forEach(async device => {
        let nowDate = new Date()
        let deviceLastSeenDate = new Date(device.lastSeen)

        if (deviceLastSeenDate.getTime() < (nowDate.getTime() - 660000)) {
          device.lastSeenStatus = "Offline"
        } else {
          device.lastSeenStatus = "Online"
        }
      });
    },
  },
  destroyed() {
    clearInterval(this.updateDevicesInterval)
  },
  unmounted() {
    clearInterval(this.updateDevicesInterval)
  },
  async mounted() {
    this.loading = true;
    if (store.getters.getUser) {
      let user = store.getters.getUser;
      let userId = user.id;
      this.updateDevices(this.$apollo, this.$router, userId, user.type)
      this.updateDevicesInterval = setInterval(async () => {
        this.updateDevices(this.$apollo, this.$router, userId, user.type)
      }, 10000)
    }
    this.loading = false;
  },
};
</script>
<style scoped>
.row-even {
  background-color: #ffffff;
}

.row-odd {
  background-color: blue;
}

.column-status {
  padding-right: 16px;
}

.column-health {
  padding-left: 16px;
}
</style>
