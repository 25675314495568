<template>
  <Preloader v-if="loading"/>

  <div class="dashboard p-4">
    <div class="mt-3 w-full">
      <h2 class="font-bold text-lg text-gray-800 dark:text-gray-200">
        Device Details
      </h2>
    </div>
    <div class="first-row wrapper-card grid lg:grid-cols-12 grid-cols-12 md:grid-cols-12 gap-5 mt-5 flex-col-reverse">
      <!-- card -->
      <div class="card-1 card bg-white dark:bg-gray-800 w-full rounded-md p-3 border dark:border-gray-700 col-span-5">
        <div class="block grid grid-cols-2 items-center">
          <div class="col-span-1">
            <h1 class="font-semibold text-gray-900 dark:text-gray-200 text-xl d-inline-block">
              Trident Device
            </h1>
          </div>
          <div class="col-span-1">
            <img src="@/assets/logo/tisagw_logo.png" alt="Description de l'image" class=""/>
          </div>
          <div class="mt-2 col-span-6">
            <p class="text-l text-white font-semibold mt-2">
              Battery Chemistry: {{ this.deviceBatteryChemistry }}
            </p>
            <br>
            <p class="text-l text-white font-semibold mt-2">
              Software Version: {{ this.liveSoftwareVersion }}
            </p>
            <br>
            <p class="text-l text-white font-semibold mt-2">
              SW Update Date: {{ this.liveSoftwareVersionTime }}
            </p>
          </div>
        </div>
      </div>
      <!-- end card -->
      <div class="card-2 flex justify-end col-span-7">
        <div
            class="card bg-white dark:bg-gray-800 w-3/6 rounded-md p-3 border dark:border-gray-700 flex items-center mr-4 col-span-3 flex-item-1">
          <h1 class="font-semibold text-gray-900 dark:text-gray-200 text-xl flex-shrink-0 mr-4">
            State of charge
          </h1>
          <div ref="socchart" class="w-full" :style="{ height: '250px', width: '250px' }"></div>
        </div>
        <!-- end card -->
        <div
            class="card bg-white dark:bg-gray-800 w-3/6 rounded-md p-3 border dark:border-gray-700 flex items-center col-span-3 flex-item-1">
          <h1 class="font-semibold text-gray-900 dark:text-gray-200 text-xl flex-shrink-0 mr-4">
            State of Health
          </h1>
          <div ref="sohchart" class="w-full" :style="{ height: '250px', width: '250px' }"></div>
        </div>
      </div>
      <div class="flex gap-5 col-span-5 card-3">

        <div
            class="inner-card card grid grid-cols-12 w-full p-3 border dark:border-gray-700 items-center max-h-24 rounded-md p-6 border dark:border-gray-700 mr-4"
            :class="{
    'bg-green-400': ['healthy', 'online', 'active', 'new'].includes(
      this.deviceStatus.toLowerCase()
    ),
    'bg-red-400': this.deviceStatus.toLowerCase() === 'offline',
    'bg-orange-400': ![
      'healthy',
      'online',
      'active',
      'offline',
      'new',
    ].includes(this.deviceStatus.toLowerCase()),
  }">
          <div class="col-span-6">
            <h3 class="font-semibold text-black-900 dark:text-black-200 text-l">
              Device Name: {{ this.deviceName }}
            </h3>
          </div>
          <div class="text-right col-span-6 text-xl">
            <h3 class="font-semibold text-black-900 dark:text-black-200 text-l">
              Status: {{ getStatus }}
            </h3>
          </div>
          <p class="font-semibold text-black-900 dark:text-black-200 text-l col-span-12">
            Last seen:
            <span class="text-white-700">{{
                this.deviceLastSeen.replace("T", " ").split(".")[0]
              }}</span>
          </p>
        </div>

        <!--- Begin Energy Usage --->
        <div class="card-1 card bg-white dark:bg-gray-800 w-full rounded-md border dark:border-gray-700 col-span-8">
          <div class="block grid grid-cols-2 items-center">
            <div class="col-span-2 pt-0">
              <div class="block w-full bg-blue-500 text-white text-center">
                <p>Energy Usage</p>
              </div>
            </div>
            <div class="col-span-2 text-center">
              <div  v-if="isEnergyLoader"
                    class="card w-full p-2 border dark:border-gray-700 items-center mt-auto  mr-4 col-span-5 dark:bg-gray-800">
                <SubLoader   />
              </div>
              <div v-else>
                <!-- First inner card -->
                <div
                    class="inner-card grid grid-cols-12 text-white card w-full p-3 border dark:border-gray-700 items-center mt-auto  mr-4 col-span-5 dark:bg-gray-800">
                  <div class="col-span-5 text-center mr-1">
                    <p class="font-semibold text-black-900 dark:text-black-200 text-lg">
                      Last Power Update
                    </p>
                  </div>
                  <div class="col-span-3 text-center mr-1">
                    <p class="font-semibold text-black-900 dark:text-black-200 text-lg">
                      Power In
                    </p>
                  </div>
                  <div class="col-span-3 text-center">
                    <p class="font-semibold text-black-900 dark:text-black-200 text-lg">
                      Power Out
                    </p>
                  </div>
                  <div class="col-span-5 text-center text-sm mr-1">
                    <p class="font-semibold text-black-900 dark:text-black-200">
                      {{ this.deviceLastSeen.replace("T", " ").split(".")[0] }}
                    </p>
                  </div>
                  <div class="col-span-3 text-center text-sm mr-1">
                    <p class="font-semibold text-black-900 dark:text-black-200">
                      {{ this.livePowerIn.toFixed(2) }} KW
                    </p>
                  </div>
                  <div class="col-span-3 text-center text-sm">
                    <p class="font-semibold text-black-900 dark:text-black-200">
                      {{ this.livePowerOut.toFixed(2) }} KW
                    </p>
                  </div>
                </div>

                <!-- Second inner card -->
                <div
                    class="inner-card grid grid-cols-12 text-white card w-full p-3 border dark:border-gray-700 items-center mt-auto rounded-bl-md rounded-br-md mr-4 col-span-5 dark:bg-gray-800">
                  <div class="col-span-5 text-center mr-1 flex items-center">
                    <button style="background-color: transparent; border: none; cursor: pointer; padding: 0;"
                            @click="showCalendarModal">
                      <Icon icon="mdi:calendar-month"
                            style="color: black; background-color: #02ff00; padding: 2px; border-radius: 4px; font-size: 30px;"
                            class="mr-2"/>
                    </button>
                    <p class="font-normal text-black-900 dark:text-black-200 text-lg">
                      <span class="font-bold">{{ msgSelectedDateRangeCount }}</span> Accumulated
                    </p>
                  </div>


                  <div class="col-span-3 text-center mr-1">
                    <p class="font-semibold text-black-900 dark:text-black-200 text-lg">
                      Power In
                    </p>
                  </div>
                  <div class="col-span-3 text-center">
                    <p class="font-semibold text-black-900 dark:text-black-200 text-lg">
                      Power Out
                    </p>
                  </div>
                  <div class="col-span-5 text-center text-sm mr-1">
                    <p class="font-semibold text-black-900 dark:text-black-200">
                      {{ msgSelectedDateRange }}
                    </p>
                  </div>
                  <div class="col-span-3 text-center text-sm mr-1">
                    <p class="font-semibold text-black-900 dark:text-black-200">
                      {{ this.liveSelectedDateRangePowerIn.toFixed(2) }} KW
                    </p>
                  </div>
                  <div class="col-span-3 text-center text-sm">
                    <p class="font-semibold text-black-900 dark:text-black-200">
                      {{ this.liveSelectedDateRangePowerOut.toFixed(2) }} KW
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--- End Energy Usage --->

      </div>

      <div
          class="card-4 card bg-white dark:bg-gray-800 flex-grow p-3 rounded-md border dark:border-gray-700 w-full h-full col-span-7">
        <div class="flex flex-wrap justify-between">
          <div class="w-full lg:w-1/2 p-2">
            <h3 class="font-semibold text-gray-900 dark:text-gray-200 text-xl text-center">
              Voltage
            </h3>
            <div class="w-full h-48 mt-4">
              <div ref="voltchart" class="w-full h-48 mt-4"></div>
            </div>
          </div>
          <div class="w-full lg:w-1/2 p-2">
            <h3 class="font-semibold text-gray-900 dark:text-gray-200 text-xl text-center">
              Temperature
            </h3>
            <div class="w-full h-48 mt-4">
              <div ref="tempchart" class="w-full h-48 mt-4 pt-4"></div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="first-row wrapper-card grid lg:grid-cols-12 grid-cols-12 md:grid-cols-12 gap-5 mt-5 flex-col-reverse">
      <!-- card for Inverter Module -->
      <div class="card-1 card bg-white dark:bg-gray-800 w-full rounded-md border dark:border-gray-700 col-span-4">
        <div class="block grid grid-cols-2 items-center overflow-auto">
          <div class="col-span-2 bg-orange-500 pt-0">
            <div class="block w-full bg-orange-500 text-black text-center">
              <p> Inverter Module </p>
            </div>
          </div>
          <div  v-if="isInverterLoader"
                class="card w-full p-2 pt-20 dark:border-gray-700 items-center mt-auto  mr-4 col-span-5 dark:bg-gray-800">
            <SubLoader   />
          </div>
          <div v-else>
            <div class="col-span-2 text-center">
              <ul
                  class="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400">
                <li class="me-2">
                  <a href="#" aria-current="page" @click="inverterModuleTab = 'status'"
                     :class="{ 'text-blue-600 active dark:text-blue-500': inverterModuleTab === 'status', 'hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300': inverterModuleTab !== 'status' }"
                     class="inline-block p-4 bg-gray-100 rounded-t-lg dark:bg-gray-800">Status</a>
                </li>
                <li class="me-2">
                  <a href="#" @click="inverterModuleTab = 'details'"
                     :class="{ 'text-blue-600 active dark:text-blue-500': inverterModuleTab === 'details', 'hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300': inverterModuleTab !== 'details' }"
                     class="inline-block p-4 bg-gray-100 rounded-t-lg dark:bg-gray-800">Module Details</a>
                </li>
              </ul>
            </div>
            <div class="mt-2 p-3 pt-0 col-span-2" v-if="inverterModuleTab == 'status'">
              <p class="text-l text-white font-semibold mt-2">
                State: {{ inverterModule.status.text }}
                <span :class="{
    'bg-green-500': inverterModule.status.status === 'success',
    'bg-orange-500': inverterModule.status.status === 'warning',
    'bg-red-500': inverterModule.status.status === 'danger',
    'bg-blue-500': inverterModule.status.status === 'info',
  }" class="inline-block w-3 h-3 ml-2 rounded-full"></span>
              </p>
              <br>
            </div>
            <div class="mt-2 p-3 pt-0 col-span-2" v-if="inverterModuleTab == 'details'">
              <p class="text-l text-white font-semibold mt-2">
                Manufacturer: {{ inverterModule.manufacturer }}
              </p>
              <p class="text-l text-white font-semibold mt-2">
                Model: {{ inverterModule.model }}
              </p>
              <p class="text-l text-white font-semibold mt-2">
                Version: {{ inverterModule.version }}
              </p>
              <p class="text-l text-white font-semibold mt-2">
                Serial Number: {{ inverterModule.serialNumber }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- end card for Inverter Module -->
      <!-- card for BMS Module -->
      <div
          class="card-1 card bg-white dark:bg-gray-800 w-full rounded-md border dark:border-gray-700 col-span-8 overflow-auto h-80">
        <div class="block grid grid-cols-2 items-center">
          <div class="col-span-2 pt-0">
            <div class="block w-full bg-lime-500 text-black text-center">
              <p> BMS Module </p>
            </div>
          </div>
          <div  v-if="isBMSLoader"
                class="card w-full p-2 pt-20 dark:border-gray-700 items-center mt-auto  mr-4 col-span-5 dark:bg-gray-800">
            <SubLoader   />
          </div>
          <div class="grid grid-cols-2 col-span-2" v-else>
            <div class="col-span-2 text-center">
              <ul
                  class="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400">
                <li class="me-2">
                  <a href="#" aria-current="page" @click="bmsModuleTab = 'status'"
                     :class="{ 'text-blue-600 active dark:text-blue-500': bmsModuleTab === 'status', 'hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300': bmsModuleTab !== 'status' }"
                     class="inline-block p-4 bg-gray-100 rounded-t-lg dark:bg-gray-800">Status</a>
                </li>
                <li class="me-2">
                  <a href="#" @click="bmsModuleTab = 'details'"
                     :class="{ 'text-blue-600 active dark:text-blue-500': bmsModuleTab === 'details', 'hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300': bmsModuleTab !== 'details' }"
                     class="inline-block p-4 bg-gray-100 rounded-t-lg dark:bg-gray-800">Module Details</a>
                </li>
                <li class="me-2" v-for="pack in batteryPacks" :key="pack.battery_id">
                  <a href="#" @click="bmsModuleTab = 'pack' + pack.battery_id"
                     :class="{ 'text-blue-600 active dark:text-blue-500': bmsModuleTab === 'pack' + pack.battery_id, 'hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300': bmsModuleTab !== 'pack' + pack.battery_id }"
                     class="inline-block p-4 bg-gray-100 rounded-t-lg dark:bg-gray-800">Pack {{ pack.battery_id }}</a>
                </li>
              </ul>
            </div>
            <div class="mt-2 p-3 pt-0 col-span-2" v-if="bmsModuleTab == 'status'">
              <p class="text-l text-white font-semibold mt-2">
                Mode: {{ bmsModule.mode.text }}
                <span :class="{
    'bg-green-500': bmsModule.mode.status === 'success',
    'bg-red-500': bmsModule.mode.status === 'warning',
    'bg-orange-500': bmsModule.mode.status === 'danger',
    'bg-blue-500': bmsModule.mode.status === 'info',
  }" class="inline-block w-3 h-3 ml-2 rounded-full"></span>
              </p>
              <p class="text-l text-white font-semibold mt-2 col-span-1 grid grid-cols-2" v-for="pack in batteryPacks"
                 :key="pack.battery_id">
                <p class="col-span-2 text-bold"> Pack {{ pack.battery_id }} </p>
                <p class="col-span-1"> Status: {{ pack.status.text }}
                  <span :class="{
    'bg-green-500': pack.status.status === 'success',
    'bg-red-500': pack.status.status === 'warning',
    'bg-orange-500': pack.status.status === 'danger',
    'bg-blue-500': pack.status.status === 'info',
  }" class="inline-block w-3 h-3 ml-2 rounded-full"></span>
                </p>
                <p class="col-span-1">
                  Failure Prediction: {{ this.formatMonths(pack.failurePrediction) }}
                </p>
              </p>
            </div>
            <div class="mt-2 p-3 pt-0 col-span-2" v-if="bmsModuleTab == 'details'">
              <p class="text-l text-white font-semibold mt-2">
                Manufacturer: {{ bmsModule.manufacturer }}
              </p>
              <p class="text-l text-white font-semibold mt-2">
                Model: {{ bmsModule.model }}
              </p>
            </div>
            <template v-for="pack in batteryPacks" :key="pack.battery_id" class="">
              <div class="mt-2 p-3 pt-0 col-span-1 align-text-top border-r border-gray-500"
                   v-if="bmsModuleTab == 'pack' + pack.battery_id">
                <p class="text-l text-white font-semibold mt-2">
                  Status: {{ pack.status.text }}
                  <span :class="{
    'bg-green-500': pack.status.status === 'success',
    'bg-red-500': pack.status.status === 'warning',
    'bg-orange-500': pack.status.status === 'danger',
    'bg-blue-500': pack.status.status === 'info',
  }" class="inline-block w-3 h-3 ml-2 rounded-full"></span>
                </p>
                <p class="text-l text-white font-semibold mt-2">
                  SOC: {{ pack.soc }}%
                </p>
                <p class="text-l text-white font-semibold mt-2">
                  SOH: {{ pack.soh }}%
                </p>
                <p class="text-l text-white font-semibold mt-2">
                  Cycles: {{ pack.cycles }}
                </p>
                <p class="text-l text-white font-semibold mt-2">
                  Temperature: {{ pack.temperature }}°C
                </p>
                <p class="text-l text-white font-semibold mt-2">
                  Battery Failure Prediction: {{ this.formatMonths(pack.failurePrediction) }}
                </p>
              </div>
              <div class="mt-2 p-3 pt-0 col-span-1 grid grid-cols-2" v-if="bmsModuleTab == 'pack' + pack.battery_id">
                <p class="text-l text-white font-semibold mt-2 col-span-2 text-center">Cells </p>
                <p class="text-l text-white font-semibold mt-2 col-span-1 text-center"
                   v-for="(cell, index) in pack.cells"
                   :key="index">
                  Cell {{ (index+1) + ": " + cell + "V"}}
                </p>
              </div>
            </template>
          </div>
        </div>
      </div>
      <!-- end card for BMS Module -->
    </div>
    <div class="my-5 card bg-white dark:bg-gray-800 w-full rounded-md p-5 border dark:border-gray-700">
      <div>
        <div class="w-full text-lg text-orange-600 mx-auto">
          <div class="block w-full">
            <p class="text-gray-900 dark:text-gray-200"> Power Dispatch </p>
          </div>
          <div class="flex h-screen" v-if="noMeterData" :style="{ height: '300px' }">
            <div class="m-auto"> No data available</div>
          </div>
          <div id="powerDispatchChart" class="w-full" :style="{ height: '300px' }"></div>
        </div>
      </div>
    </div>

    <div class="my-5 card bg-white dark:bg-gray-800 w-full rounded-md p-5 border dark:border-gray-700">
      <div>
        <div class="w-full text-lg text-orange-600 mx-auto">
          <div class="block w-full">
            <p class="text-gray-900 dark:text-gray-200"> Battery Charge </p>
          </div>
          <div class="flex h-screen" v-if="noBMSData" :style="{ height: '300px' }">
            <div class="m-auto"> No data available</div>
          </div>
          <div id="powerChargeChart" class="w-full" :style="{ height: '300px' }"></div>
        </div>
      </div>
    </div>
  </div>

  <CalendarModel v-if="showDateRangeDialog" @save="getDataOfSelectedDateRange" @close-model="closeCalendarModal"/>

</template>

<script>
import * as echarts from "echarts";

import store, {getDevicesByIdQuery, getMessagesQuery} from "@/store/store";
import checkToken from "@/jwt";
import VueApexCharts from "vue3-apexcharts";
import Preloader from "@/components/Preloader.vue";
import {Icon} from "@iconify/vue";
import CalendarModel from "@/components/models/CalendarModel.vue";
import SubLoader from "@/components/SubLoader.vue";

export default {
  props: ["id"],
  components: {
    CalendarModel,
    Icon,
    Preloader,
    SubLoader,
    apexchart: VueApexCharts,
  },
  data() {
    this.lineChartOptions = {
      noData: {
        text: "No data available",
        align: "center",
        verticalAlign: "middle",
        style: {
          color: "#02FF00",
          fontSize: "20px",
        },
      },
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      title: {
        align: "left",
        style: {
          fontSize: "14px",
          fontWeight: "bold",
          color: "white",
        },
      },
      legend: {
        labels: {
          colors: "white",
        },
      },
      grid: {},
      xaxis: {
        categories: [""],
        labels: {
          style: {
            colors: "#ffffff", // Set y-axis labels to white
          },
          width: "20%",
          align: "left",
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: "#ffffff", // Set y-axis labels to white
          },
        },
      },
    };
    return {
      isEnergyLoader: false,
      isBMSLoader: false,
      isInverterLoader: false,
      showDateRangeDialog: false,
      liveSelectedDateRangePowerIn: 0,
      liveSelectedDateRangePowerOut: 0,
      meterMessages: [],
      inverterModule: {
        status: "",
        mode: "",
        manufacturer: "",
        model: "",
        version: "",
        serialNumber: ""
      },
      bmsModule: {
        status: "",
        mode: {},
        manufacturer: "",
        model: ""
      },
      bmsMessages: [],
      msgSelectedDateRange: "",
      msgSelectedDateRangeCount: "24 Hours",
      selectedDateRange: {},
      batteryPacks: [],
      inverterModuleTab: "status",
      bmsModuleTab: "status",
      /* POWER DISPATCH VARIABLES */
      powerDispatchChart: null,
      powerDispatchChartOptions: {
        title: {},
        tooltip: {
          trigger: 'item',
          formatter: function (value) {
            return `${value.seriesName}:
              ${value.data[1].toFixed(2)}`;
          }
        },
        legend: {
          data: ['Power In', 'Power Out'],
          orient: 'vertical',
          right: 10,
          top: 'center',
          textStyle: {
            color: 'white'
          }
        },
        xAxis: {
          type: 'time', // Use 'time' for datetime x-axis
          axisLabel: {
            showEmpty: false, // Hide labels when no data is available
            formatter: function (value, index) {
              const date = new Date(value);
              const now = new Date();
              const diff = now - date;
              // Array of abbreviated month names
              const monthNamesAbbreviated = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

              if (diff <= 2 * 60 * 60 * 1000) { // Less than 2 hours
                const hours = date.getHours();
                const minutes = date.getMinutes();
                return `${hours}:${minutes < 10 ? '0' + minutes : minutes}`;
              } else if (diff <= 24 * 60 * 60 * 1000) { // Less than 24 hours
                const hours = date.getHours();
                return `${hours}:00`;
              } else { // More than 24 hours
                const month = monthNamesAbbreviated[date.getMonth()];
                const day = date.getDate();
                return `${day} ${month} `;
              }
            },
            color: 'white', // Set the color of xAxis labels to white
          },
        },
        yAxis: {
          name: 'Power (KW)', // Title for the y-axis
          nameTextStyle: {
            color: 'white', // Set the color of yAxis title to white
            verticalAlign: 'middle', // Align title vertically
          },
          axisLabel: {
            color: 'white', // Set the color of yAxis labels to white
          },
          splitLine: {
            show: false
          },
        },
        toolbox: {
          left: 'center',
          feature: {
            dataZoom: {
              yAxisIndex: 'none'
            },
            restore: {},
          }
        },
        dataZoom: [
          {
            id: 'dataZoomX',
            type: 'slider',
            xAxisIndex: [0],
            filterMode: 'filter'
          },
        ],
        series: [],
      },
      /* POWER CHARGE CHART VARIABLES */
      powerChargeChart: null,
      powerChargeChartOptions: {
        title: {},
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          right: 10,
          top: 'center',
          textStyle: {
            color: 'white'
          }
        },
        xAxis: {
          type: 'time', // Use 'time' for datetime x-axis
          axisLabel: {
            showEmpty: false, // Hide labels when no data is available
            formatter: function (value, index) {
              const date = new Date(value);
              const now = new Date();
              const diff = now - date;
              // Array of abbreviated month names
              const monthNamesAbbreviated = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

              if (diff <= 2 * 60 * 60 * 1000) { // Less than 2 hours
                const hours = date.getHours();
                const minutes = date.getMinutes();
                return `${hours}:${minutes < 10 ? '0' + minutes : minutes}`;
              } else if (diff <= 24 * 60 * 60 * 1000) { // Less than 24 hours
                const hours = date.getHours();
                return `${hours}:00`;
              } else { // More than 24 hours
                const month = monthNamesAbbreviated[date.getMonth()];
                const day = date.getDate();
                return `${day} ${month} `;
              }
            },
            color: 'white', // Set the color of xAxis labels to white
          },
        },
        yAxis: {
          name: 'State of Charge (%)', // Title for the y-axis
          type: 'value',
          nameTextStyle: {
            color: 'white', // Set the color of yAxis title to white
            verticalAlign: 'middle', // Align title vertically
          },
          axisLabel: {
            color: 'white', // Set the color of yAxis labels to white
          },
          splitLine: {
            show: false
          },
        },
        toolbox: {
          left: 'center',
          feature: {
            dataZoom: {
              yAxisIndex: 'none'
            },
            restore: {},
          }
        },
        dataZoom: [
          {
            id: 'dataZoomX',
            type: 'slider',
            xAxisIndex: [0],
            filterMode: 'filter'
          },
        ],
        series: [],
      },
      /* UPDATE INVERTAL */
      updateDevicesInterval: "",
      tempChartOptions: {
        series: [
          {
            type: "gauge",
            center: ["50%", "60%"],
            startAngle: 200,
            endAngle: -20,
            min: 0,
            max: 50,
            splitNumber: 12,
            itemStyle: {
              color: "#02FF00",
            },
            progress: {
              show: true,
              width: 10,
            },
            pointer: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                width: 10,
              },
            },
            axisTick: {
              distance: -45,
              splitNumber: 5,
              lineStyle: {
                width: 2,
                color: "#999",
              },
            },
            splitLine: {
              distance: -46,
              length: 10,
              lineStyle: {
                width: 3,
                color: "#999",
              },
            },
            axisLabel: {
              distance: 15,
              color: "#02FF00",
              fontSize: 10,
              formatter: function (value) {
                return Math.round(value);
              },
            },
            anchor: {
              show: false,
            },
            title: {
              show: false,
            },
            detail: {
              valueAnimation: true,
              width: "60%",
              lineHeight: 10,
              borderRadius: 8,
              offsetCenter: [0, "-15%"],
              fontSize: 20,
              fontWeight: "bolder",
              formatter: "{value} °C",
              color: "inherit",
            },
            data: [
              {
                value: 20.0,
              },
            ],
          },
          {
            type: "gauge",
            center: ["50%", "60%"],
            startAngle: 200,
            endAngle: -20,
            min: 0,
            max: 60,
            itemStyle: {
              color: "#02FF00",
            },
            progress: {
              show: true,
              width: 8,
            },
            pointer: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            detail: {
              show: false,
            },
            data: [
              {
                value: 20.0,
              },
            ],
          },
        ],
      },
      voltChartOptions: {
        series: [
          {
            type: "gauge",
            center: ["50%", "60%"],
            startAngle: 200,
            endAngle: -20,
            min: 0,
            max: 30,
            splitNumber: 12,
            itemStyle: {
              color: "#02FF00",
            },
            progress: {
              show: true,
              width: 10,
            },
            pointer: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                width: 10,
              },
            },
            axisTick: {
              distance: -45,
              splitNumber: 5,
              lineStyle: {
                width: 2,
                color: "#999",
              },
            },
            splitLine: {
              distance: -46,
              length: 10,
              lineStyle: {
                width: 3,
                color: "#999",
              },
            },
            axisLabel: {
              distance: 15,
              color: "#02FF00",
              fontSize: 10,
              formatter: function (value) {
                return Math.round(value);
              },
            },
            anchor: {
              show: false,
            },
            title: {
              show: false,
            },
            detail: {
              valueAnimation: true,
              width: "60%",
              lineHeight: 10,
              borderRadius: 8,
              offsetCenter: [0, "-15%"],
              fontSize: 20,
              fontWeight: "bolder",
              formatter: "{value} V",
              color: "inherit",
            },
            data: [
              {
                value: 20.0,
              },
            ],
          },
          {
            type: "gauge",
            center: ["50%", "60%"],
            startAngle: 200,
            endAngle: -20,
            min: 0,
            max: 60,
            itemStyle: {
              color: "#02FF00",
            },
            progress: {
              show: true,
              width: 8,
            },
            pointer: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            detail: {
              show: false,
            },
            data: [
              {
                value: 20.0,
              },
            ],
          },
        ],
      },
      chargeChartOptions: {
        series: [
          {
            type: 'gauge',
            axisLine: {
              lineStyle: {
                width: 15,
                color: [
                  [0.25, '#fd666d'],    // Less than 25%: Red
                  [0.5, '#FFA500'],     // Between 25% and 50%: Orange
                  [0.75, '#37a2da'],    // Between 50% and 75%: Blue sky
                  [1, '#00FF00']        // Between 75% and 100%: Green
                ]
              }
            },
            pointer: {
              itemStyle: {
                color: 'auto'
              }
            },
            axisTick: {
              distance: -7,
              length: 7,
              lineStyle: {
                color: '#fff',
                width: 2
              }
            },
            splitLine: {
              distance: -15,
              length: 15,
              lineStyle: {
                color: '#fff',
                width: 2
              }
            },
            axisLabel: {
              color: 'inherit',
              distance: 20,
              fontSize: 13
            },
            detail: {
              fontSize: 22,
              valueAnimation: true,
              formatter: '{value} %',
              color: 'inherit'
            },
            data: [
              {
                value: 70
              }
            ]
          }
        ]
      },
      healthChartOptions: {
        series: [
          {
            type: 'gauge',
            axisLine: {
              lineStyle: {
                width: 15,
                color: [
                  [0.25, '#fd666d'],    // Less than 25%: Red
                  [0.5, '#FFA500'],     // Between 25% and 50%: Orange
                  [0.75, '#37a2da'],    // Between 50% and 75%: Blue sky
                  [1, '#00FF00']        // Between 75% and 100%: Green
                ]
              }
            },
            pointer: {
              itemStyle: {
                color: 'auto'
              }
            },
            axisTick: {
              distance: -7,
              length: 7,
              lineStyle: {
                color: '#fff',
                width: 2
              }
            },
            splitLine: {
              distance: -15,
              length: 15,
              lineStyle: {
                color: '#fff',
                width: 2
              }
            },
            axisLabel: {
              color: 'inherit',
              distance: 20,
              fontSize: 13
            },
            detail: {
              fontSize: 22,
              valueAnimation: true,
              formatter: '{value} %',
              color: 'inherit'
            },
            data: [
              {
                value: 70
              }
            ]
          }
        ]
      },
      loading: false,
      liveSOC: "",
      liveSOH: "",
      liveTemp: "",
      liveVolt: "",
      online: true,
      deviceData: [],
      batteryData: [],
      deviceName: "",
      batteryCharge: [],
      dischargeToGrid: [],
      dischargeToLocal: [],
      batterychargeSeries: [],
      batteryDischargeSeries: [],
      batteryDischargeToLocalSeries: [],
      deviceBatteryChemistry: "",
      deviceLastSeen: "",
      deviceinsertedAt: "",
      deviceStatus: "",
      devicePowerDispatched: null,
      lineSeries: [],
      batteryChargeStatusSeries: [],
      liveSoftwareVersion: "",
      liveSoftwareVersionTime: "",
      livePowerIn: 0,
      livePowerOut: 0,
      noBMSData: false,
      bmsDataLoading: true,
      noMeterData: false,
      meterDataLoading: true,
    };
  },
  computed: {
    getStatus() {
      const dateCreate = new Date(this.deviceinsertedAt);
      const currentTime = new Date();

      // Convert current time and device creation time to UTC
      const currentUTCTime = Date.UTC(
          currentTime.getUTCFullYear(),
          currentTime.getUTCMonth(),
          currentTime.getUTCDate(),
          currentTime.getUTCHours(),
          currentTime.getUTCMinutes(),
          currentTime.getUTCSeconds(),
          currentTime.getUTCMilliseconds()
      );
      const deviceUTCTime = Date.UTC(
          dateCreate.getUTCFullYear(),
          dateCreate.getUTCMonth(),
          dateCreate.getUTCDate(),
          dateCreate.getUTCHours(),
          dateCreate.getUTCMinutes(),
          dateCreate.getUTCSeconds(),
          dateCreate.getUTCMilliseconds()
      );
      const status = this.deviceStatus;
      if (
          status === "online" &&
          currentUTCTime - deviceUTCTime < 1 * 60 * 60 * 1000
      )
        return "New";
      else return status;
    },
    dataDevices() {
      return store.getters.getDevice;
    },
    async deviceDetailsInfo() {
      return await store.getters.getDeviceDetailsInfo; // Getter to retrieve deviceDetailsInfo from the Vuex store
    },
  },
  methods: {
    formatMonths(value) {
      if (value < 1) {
        return "< 1 month";
      }

      const roundedValue = Math.floor(value);
      return `less than ${roundedValue + 1} month${roundedValue + 1 > 1 ? "s" : ""}`;
    },
    showCalendarModal() {
      this.showDateRangeDialog = true;
    },
    closeCalendarModal() {
      this.showDateRangeDialog = false;
    },
    async getDataOfSelectedDateRange(dateRange) {
      this.liveSelectedDateRangePowerIn = 0;
      this.liveSelectedDateRangePowerOut = 0;

      await this.getMessagesByDeviceIdAndTimeRange(this.id, "Meter", dateRange);

      const begin = new Date(dateRange.begin);
      const end = new Date(dateRange.end);

      const options = {day: '2-digit', month: 'short'}; // Format for "10 Sep"
      const beginDate = begin.toLocaleDateString('en-GB', options);
      const endDate = end.toLocaleDateString('en-GB', options);
      this.msgSelectedDateRange = `(${beginDate}, ${endDate})`;

      // Calculate the time difference in milliseconds
      const timeDiff = end - begin;
      const hoursDiff = timeDiff / (1000 * 60 * 60); // Convert milliseconds to hours
      const daysDiff = Math.floor(hoursDiff / 24);   // Convert hours to days

      if (hoursDiff < 48) {
        this.msgSelectedDateRangeCount = `${hoursDiff.toFixed(0)} Hours`; // Display in hours
      } else {
        this.msgSelectedDateRangeCount = `${daysDiff} Days`; // Display in days
      }

      this.meterMessages.forEach((message) => {
        let parsedMessage = JSON.parse(message.messageText);

        this.liveSelectedDateRangePowerIn += parsedMessage.pwrIn ? parseInt(parsedMessage.pwrIn) : 0;
        this.liveSelectedDateRangePowerOut += parsedMessage.pwrOut ? parseInt(parsedMessage.pwrOut) : 0;
      });
      this.isEnergyLoader = false;
    },
    async getMessagesByDeviceIdAndTimeRange(deviceId, type, dateRange) {
      this.meterMessages = []
      try {
        const data = await this.$apollo.query({
          query: getMessagesQuery.getLast24HoursMessages,
          variables: {
            deviceId: deviceId,
            messageType: type,
            begin: dateRange.begin,
            end: dateRange.end,
          },
        });
        if (data?.data?.messages?.messages) {
          const messages = data.data.messages.messages;
          if (messages.length > 0) {
            this.meterMessages = messages.map((message) => ({...message}));
          }
        }
      } catch (error) {
        console.error("Error in Device Details get messages By DeviceId And Time Range => ", error);
      }
    },
    parseInverterState(state) {
      switch (state) {
        case "1":
          return {status: "danger", text: "Off"};
        case "2":
          return {status: "warning", text: "Sleeping"};
        case "3":
          return {status: "success", text: "Starting"};
        case "4":
          return {status: "info", text: "MPPT"};
        case "5":
          return {status: "info", text: "Throttled"};
        case "6":
          return {status: "warning", text: "Shutting Down"};
        case "7":
          return {status: "danger", text: "Fault"};
        case "8":
          return {status: "info", text: "Standby"};
      }
    },
    parsePackState(soh) {
      if (parseFloat(soh) > 50) {
        return {status: "info", text: "Healthy"};
      } else if (parseFloat(soh) > 30) {
        return {status: "success", text: "Not Ok"};
      } else if (parseFloat(soh) > 20) {
        return {status: "warning", text: "Warning"};
      } else {
        return {status: "danger", text: "Critical"};
      }
    },
    parseBmsMode(mode) {
      switch (mode) {
        case "Standby":
          return {status: "info", text: "Standby"};
        case "Commissioning":
          return {status: "warning", text: "Commissioning"};
        case "Active":
          return {status: "success", text: "Active"};
        case "OTP":
          return {status: "warning", text: "OTP"};
        case "Monitoring":
          return {status: "info", text: "Throttled"};
      }
    },
    initTempChart(temp) {
      const chartDom = this.$refs.tempchart;
      const myChart = echarts.init(chartDom);

      this.tempChartOptions.series[0].data[0].value = temp;
      myChart.setOption(this.tempChartOptions);
    },
    initVoltChart(voltage) {
      const chartDom = this.$refs.voltchart;
      const myChart = echarts.init(chartDom);

      this.voltChartOptions.series[0].data[0].value = voltage;
      myChart.setOption(this.voltChartOptions);
    },
    initSOHChart(soh) {
      const chartDom = this.$refs.sohchart;
      const myChart = echarts.init(chartDom);

      this.healthChartOptions.series[0].data[0].value = soh;
      myChart.setOption(this.healthChartOptions);
    },
    initSOCChart(soc) {
      const chartDom = this.$refs.socchart;
      const myChart = echarts.init(chartDom);

      this.chargeChartOptions.series[0].data[0].value = soc;
      myChart.setOption(this.chargeChartOptions);
    },

    renderSocChart(messages) {
      let todayMinus24Timestamp = new Date().getTime() - 86400000;
      let todayMinus24Date = new Date(todayMinus24Timestamp);

      messages = messages.filter(
          (msg) => new Date(msg.receivedAt) > todayMinus24Date
      );

      messages.sort(function (a, b) {
        if (a.receivedAt > b.receivedAt) return 1;
        if (a.receivedAt < b.receivedAt) return -1;
        return 0;
      });

      const chargeData = {};

      // Iterate through the messages and parse each message's text
      messages.forEach(message => {
        const parsedMessage = JSON.parse(message.messageText);
        const timestamp = new Date(message.receivedAt); // Convert to milliseconds

        // Iterate through each battery pack in the message
        parsedMessage.batteryPacks.forEach(pack => {
          const packId = pack.battery_id;

          // If this battery pack's ID is not already in chargeData, initialize it
          if (!chargeData[packId]) {
            chargeData[packId] = [];
          }

          // Add the SOC data point for this battery pack
          chargeData[packId].push({
            time: timestamp,
            soc: parseFloat(pack.battery_soc)
          });
        });
      });

      // Sort each pack's data array by time
      Object.values(chargeData).forEach(pack => {
        pack.sort((a, b) => a.time - b.time);
      });

      // Convert chargeData to the format needed for the ECharts series
      const powerChargeSeries = Object.keys(chargeData).map(packId => ({
        name: `SOC - Pack ${packId}`,
        type: "line",
        data: chargeData[packId].map(item => [item.time, item.soc]), // Map to [datetime, SOC value] format
        smooth: true // Smooth the line
      }));

      this.powerChargeChartOptions.series = powerChargeSeries;

      if (this.powerChargeChart) {
        this.powerChargeChart.setOption(this.powerChargeChartOptions);
      }
      this.powerChargeChart.hideLoading()
    },
    renderDispatchChart(messages) {
      let todayMinus24Timestamp = new Date().getTime() - 86400000;
      let todayMinus24Date = new Date(todayMinus24Timestamp);

      messages = messages.filter(
          (msg) => new Date(msg.receivedAt) > todayMinus24Date
      );

      messages.sort(function (a, b) {
        if (a.receivedAt > b.receivedAt) return 1;
        if (a.receivedAt < b.receivedAt) return -1;
        return 0;
      });

      let powerData = []

      messages.forEach((message) => {
        let parsedMessage = JSON.parse(message.messageText);

        powerData.push({
          time: message.receivedAt,
          powerIn: parsedMessage.pwrIn,
          powerOut: parsedMessage.pwrOut,
        })
      });

      const powerDispatchSeries = [
        {
          name: "Power In",
          type: "line",
          data: powerData.map(item => [new Date(item.time), item.powerIn]), // Map to [datetime, value] format,
          smooth: true, // Smooth the line
        },
        {
          name: "Power Out",
          type: "line",
          data: powerData.map(item => [new Date(item.time), item.powerOut]), // Map to [datetime, value] format,
          smooth: true, // Smooth the line
        }
      ]

      this.powerDispatchChartOptions.series = powerDispatchSeries;
      if (this.powerDispatchChart) {
        this.powerDispatchChart.setOption(this.powerDispatchChartOptions);
      }
      this.powerDispatchChart.hideLoading()
    },
    async getLast24HoursMessages(deviceId, type, begin, end) {
      try {
        const data = await this.$apollo.query({
          query: getMessagesQuery.getLast24HoursMessages,
          variables: {
            deviceId: deviceId,
            messageType: type,
            begin: begin,
            end: end,
          },
        });
        if (data?.data?.messages?.messages) {
          const messages = data.data.messages.messages;

          switch (type) {
            case "BMS":
              if (data.data.messages.messages.length > 0) {
                this.renderSocChart(data.data.messages.messages);
                this.noBMSData = false;
              } else {
                this.noBMSData = true
                // this.renderSocChart(data.data.messages.messages);
                document.getElementById('powerChargeChart').style.height = "0px";
                // this.powerChargeChart.hideLoading()
              }
              break;
            case "Meter":
              if (data.data.messages.messages.length > 0) {
                this.renderDispatchChart(data.data.messages.messages);
                this.noMeterData = false;
              } else {
                this.noMeterData = true;
                document.getElementById('powerDispatchChart').style.height = "0px";
              }
              this.meterDataLoading = false;
              break;
            default:
              console.error(`Unhandled message type: ${type}`);
          }
        }
      } catch (error) {
        console.error("Error in Device Details get messages => ", error);
      }
    },
    setDeviceStatus(device) {
      let nowDate = new Date()
      let deviceLastSeenDate = new Date(device.lastSeen)

      let lastSeenTimestamp = deviceLastSeenDate.getTime()
      this.deviceLastSeen = deviceLastSeenDate.toLocaleString()

      let delta = 660000
      if (lastSeenTimestamp < (nowDate.getTime() - delta)) {
        this.deviceStatus = "Offline"
      } else {
        this.deviceStatus = "Online";
      }
    },
    getLastMessage(deviceId, type) {
      this.$apollo
          .query({
            query: getMessagesQuery.getLatestMessage,
            variables: {
              deviceId: deviceId,
              messageType: type,
            },
          })
          .then(async (data) => {
            let lastMessage = data.data.messages.messages[0];
            if (type == "BMS") {
              let parsedMessage = JSON.parse(lastMessage.messageText);
              if (parsedMessage.battery_soc.toString().includes("%")) {
                this.liveSOC = parsedMessage.battery_soc.replace("%", "");
              } else {
                this.liveSOC = parsedMessage.battery_soc;
              }

              if (parsedMessage.battery_volt.toString().includes("V")) {
                this.liveVolt = parsedMessage.battery_volt.replace("V", "");
              } else {
                this.liveVolt = parsedMessage.battery_volt;
              }

              this.liveTemp = parsedMessage.battery_temperature;
              if (parsedMessage.battery_soh.toString().includes("%")) {
                this.liveSOH = parsedMessage.battery_soh.replace("%", "");
              } else {
                this.liveSOH = parsedMessage.battery_soh;
              }
            } else if (type == "Heartbeat") {
              let lastHeartbeat = data.data.messages.messages[0];
              this.setDeviceStatus(lastHeartbeat, device);
            } else if (type == "Inverter") {

              let parsedMessage = JSON.parse(lastMessage.messageText);
              this.inverterModule.manufacturer = parsedMessage.manufacturer;
              this.inverterModule.model = parsedMessage.model;
              this.inverterModule.status = this.parseInverterState(parsedMessage.state);
              this.inverterModule.version = parsedMessage.version;
              this.inverterModule.serialNumber = parsedMessage.serial_number;
            }
          })
          .catch((error) => {
            // Error
            console.error("Error in Operator Analytics get messages => ", error);
          });
      this.isInverterLoader = false;
    },
    async parseBMSModule(deviceId) {
      await this.getBatteryPackMessages(deviceId);
      this.isBMSLoader = false;
    },
    parsePackMessages(bmsMessages) {
      let pack;
      bmsMessages.forEach(message => {
        let parsedMessage = JSON.parse(message.messageText);
        this.bmsModule.model = parsedMessage.model || "";
        this.bmsModule.manufacturer = parsedMessage.manufacturer || "";
        this.bmsModule.mode = this.parseBmsMode(parsedMessage.battery_mode) || "";
        parsedMessage.batteryPacks.forEach(packMessage => {
          pack = {
            battery_id: packMessage.battery_id || "",
            status: this.parsePackState(packMessage.battery_soh) || "",
            soc: packMessage.battery_soc || "",
            soh: packMessage.battery_soh || "",
            cycles: packMessage.battery_cycle || "",
            temperature: packMessage.battery_temperature || "",
            failurePrediction: packMessage.battery_prediction || "",
            voltage: packMessage.battery_volt || "",
            cells: this.parseBatteryCells(packMessage),
          }
          this.batteryPacks.push(pack)
        });
      });
    },
    parseBatteryCells(message) {
      return Object.keys(message)
          .filter(key => key.startsWith('battery_C') && key.endsWith('V'))
          .map(key => parseFloat(message[key]));
    },
    getFilteredMessages() {
      this.loading = true; // Start loading
      let deviceId = this.filters.device;
      let type = this.filters.type;

      this.$apollo
          .query({
            query: getMessagesQuery.getPaginatedMessagesFiltered,
            variables: {
              deviceId: deviceId,
              messageType: type,
              first: this.pageSize,
              offset: this.currentPage * this.pageSize - this.pageSize,
            },
          })
          .then(async (data) => {
            this.filteredMessages = data.data.messages.messages;
            let arrayForSort = [...this.filteredMessages];
            let filteredBySeverity = arrayForSort.filter((message) => {
              return (
                  message.severity &&
                  (this.filters.severity === "" ||
                      message.severity === this.filters.severity)
              );
            });

            this.filteredMessages = filteredBySeverity;
          })
          .catch((error) => {
            // Error
            console.error("Error in Messages get messages => ", error);
            this.loading = false; // Stop loading
          });
      this.loading = false; // Stop loading
    },
    getBatteryPackMessages(deviceId) {
      this.$apollo
          .query({
            query: getMessagesQuery.getPaginatedMessagesFiltered,
            variables: {
              deviceId: deviceId,
              messageType: "BMS",
              first: 1,
              offset: 0,
            },
          })
          .then(async (data) => {
            console.log("DEBUG GET BATTERY PACK MESSAGES => ", data)
            this.parsePackMessages(data.data.messages.messages)
          })
          .catch((error) => {
            // Error
            console.error("Error in Operator Analytics get messages => ", error);
          });
    },
    initPowerDispatchChart() {
      const chartDom = document.getElementById('powerDispatchChart');
      this.powerDispatchChart = echarts.init(chartDom);
      this.powerDispatchChart.showLoading()
    },
    initPowerChargeChart() {
      const chartDom = document.getElementById('powerChargeChart');
      this.powerChargeChart = echarts.init(chartDom);
      this.powerChargeChart.showLoading()
    },
    async getDevicesById(id) {
      try {
        await checkToken(this.$apollo, this.$router);

        const {data} = await this.$apollo.query({
          query: getDevicesByIdQuery.getDevicesByIdQuery,
          variables: {id: id},
        });

        const devicesData = data.devices.devices;
        if (devicesData.length > 0) {
          const device = devicesData[0];
          let now = new Date()
          let todayMinus24Timestamp = new Date().getTime() - 86400000
          let todayMinus24Date = new Date(todayMinus24Timestamp)

          let start = todayMinus24Date.toISOString().split(".")[0];
          let end = now.toISOString().split(".")[0];

          this.getLast24HoursMessages(device.id, "BMS", start, end);
          this.getLast24HoursMessages(device.id, "Meter", start, end);

          this.deviceBatteryChemistry = device.batteryChemistry;
          this.deviceName = device.name;
          this.devicePowerDispatched = device.powerDispatched;

          this.initSOCChart(device.bms.soc)
          this.initSOHChart(device.bms.soh)

          this.initTempChart(device.bms.temperature);
          this.initVoltChart(device.bms.voltage);
          this.setDeviceStatus(device)

          this.liveSoftwareVersion = device.softwareVersion
          this.liveSoftwareVersionTime = new Date(device.swUpdateTime).toLocaleString()
          this.livePowerIn = device.meters.pwrIn
          this.livePowerOut = device.meters.pwrOut

          //Exec functions Simultaneously
          await Promise.all([
            this.getLast24HoursMessages(
                device.id,
                "BMS",
                start,
                end,
                device.name
            ),
            this.getLast24HoursMessages(
                device.id,
                "Meter",
                start,
                end,
                device.name
            ),
          ]);
        }
      } catch (error) {
        console.error("Error in getDevicesById query => ", error);
      }
    },
  },
  destroyed() {
    clearInterval(this.updateDevicesInterval)
  },
  unmounted() {
    clearInterval(this.updateDevicesInterval)
  },
  mounted() {
    this.isEnergyLoader = true;
    this.isBMSLoader = true;
    this.isInverterLoader = true;
    // this.loading = true;
    // Log deviceDetailsInfo when the component is mounted
    this.$nextTick(async () => {
      let deviceId = null;
      // Check if id param is undefined, then use data from the Vuex store
      if (!this.id) {
        // if (!this.$route.params) {
        let deviceDetailsInfoFromLocalStorage = JSON.parse(
            localStorage.getItem("deviceDetailsInfo")
        );

        // Check if deviceDetailsInfo is present in local storage
        if (deviceDetailsInfoFromLocalStorage) {
          // Set deviceDetailsInfo in the Vuex store
          store.dispatch(
              "setDeviceDetailsInfo",
              deviceDetailsInfoFromLocalStorage
          );
          deviceId = deviceDetailsInfoFromLocalStorage.id;
          this.getDevicesById(deviceId);
          this.initPowerDispatchChart()
          this.initPowerChargeChart()
          this.updateDevicesInterval = setInterval(async () => {
            this.getDevicesById(deviceId);
          }, 100000)
        } else {
          //if id doesn't exist in local storage means something wrong so redirect to list
          this.$router.push("/component/listdevices");
        }
      } else {
        await this.getDevicesById(this.id);
        this.initPowerDispatchChart()
        this.initPowerChargeChart()

        this.selectedDateRange = {
          begin: new Date(Date.now() - 86400000).toISOString().split(".")[0], // Yesterday
          end: new Date().toISOString().split(".")[0] // Today
        };
        await this.getDataOfSelectedDateRange(this.selectedDateRange);

        // Get inverter module data
        await this.getLastMessage(this.id, "Inverter")

        // Get bms module data
        await this.parseBMSModule(this.id)

        this.updateDevicesInterval = setInterval(async () => {
          this.getDevicesById(this.id);
        }, 10000)
      }
      window.dispatchEvent(new Event("resize"));
      // this.loading = false;
    });
  },
};
</script>
<style scoped>
.card-3 {
  flex-direction: column;
}

.card-3 > * {
  height: 50% !important;
}

.chart--container {
  width: 200px !important;
}

.chart--container > * {
  width: 100% !important;
}

.flex-item {
  flex-direction: column;
  align-items: center;
  row-gap: 2rem;
}

.flex-item .chart--container {
  width: 250px !important;
}

@media screen and (max-width: 3040px) and (min-width: 1366px) {
  .flex-item-1 {
    display: flex !important;
    flex-direction: column !important;
  }
}

@media screen and (max-width: 1366px) and (min-width: 950px) {
  .flex-item-1 {
    display: flex !important;
    flex-direction: initial !important;
  }
}

@media screen and (max-width: 950px) and (min-width: 640px) {
  ::v-deep .first-row {
    grid-template-columns: 1fr;
    grid-auto-rows: repeat(5, 1fr);
    row-gap: 1.25rem;
  }

  .card-1 {
    grid-area: 1 / 1 / 2 / 12;
  }

  .card-2 {
    grid-area: 2 / 1 / 3 / 12;
  }

  .card-3 {
    grid-area: 3 / 1 / 4 / 12;
    flex-direction: column;
  }

  .card-4 {
    grid-area: 4 / 1 / 4 / 12;
  }

  .inner-card {
    height: 120px !important;
  }
}
</style>
