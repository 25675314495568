<template>
  <div class="flex flex-col items-center justify-center p-5">
    <form
      @submit.prevent="updateAddress"
      class="w-full sm:w-3/4 md:w-2/3 lg:w-1/2"
    >
      <div
        class="mb-4 flex flex-col sm:flex-row items-center sm:justify-start sm:space-x-4"
      >
        <label
          class="shadow appearance-none border-2 border-gray-500 bg-[#314359] text-white py-2 px-3 mb-2 sm:mb-0 sm:mr-2 w-[150px]"
          for="company"
          >Company</label
        >
        <input
          class="shadow appearance-none border-2 border-gray-500 bg-[#314359] text-white py-2 px-3"
          id="company"
          v-model="address.company"
          type="text"
        />
      </div>
      <div
        class="mb-4 flex flex-col sm:flex-row items-center sm:justify-start sm:space-x-4"
      >
        <label
          class="shadow appearance-none border-2 border-gray-500 bg-[#314359] text-white py-2 px-3 mb-2 sm:mb-0 sm:mr-2 w-[150px]"
          for="address"
          >Address</label
        >
        <input
          class="shadow appearance-none border-2 border-gray-500 bg-[#314359] text-white py-2 px-3"
          id="address"
          v-model="address.address"
          type="text"
        />
      </div>
      <div
        class="mb-4 flex flex-col sm:flex-row items-center sm:justify-start sm:space-x-4"
      >
        <label
          class="shadow appearance-none border-2 border-gray-500 bg-[#314359] text-white py-2 px-3 mb-2 sm:mb-0 sm:mr-2 w-[150px]"
          for="address2"
          type="text"
          >Address Line 2</label
        >
        <input
          class="shadow appearance-none border-2 border-gray-500 bg-[#314359] text-white py-2 px-3"
          id="address2"
          v-model="address.addressLine2"
          type="text"
        />
      </div>
      <div
        class="mb-4 flex flex-col sm:flex-row items-center sm:justify-start sm:space-x-4"
      >
        <label
          class="shadow appearance-none border-2 border-gray-500 bg-[#314359] text-white py-2 px-3 mb-2 sm:mb-0 sm:mr-2 w-[150px]"
          for="city"
          >City</label
        >
        <input
          class="shadow appearance-none border-2 border-gray-500 bg-[#314359] text-white py-2 px-3"
          id="city"
          v-model="address.city"
          type="text"
        />
      </div>
      <div
        class="mb-4 flex flex-col sm:flex-row items-center sm:justify-start sm:space-x-4"
      >
        <label
          class="shadow appearance-none border-2 border-gray-500 bg-[#314359] text-white py-2 px-3 mb-2 sm:mb-0 sm:mr-2 w-[150px]"
          for="County/State"
          >County/State</label
        >
        <input
          class="shadow appearance-none border-2 border-gray-500 bg-[#314359] text-white py-2 px-3"
          id="county"
          v-model="address.countyState"
          type="text"
        />
      </div>
      <div
        class="mb-4 flex flex-col sm:flex-row items-center sm:justify-start sm:space-x-4"
      >
        <label
          class="shadow appearance-none border-2 border-gray-500 bg-[#314359] text-white py-2 px-3 mb-2 sm:mb-0 sm:mr-2 w-[150px]"
          for="postcode"
          >PostCode</label
        >
        <input
          class="shadow appearance-none border-2 border-gray-500 bg-[#314359] text-white py-2 px-3"
          id="postcode"
          v-model="address.postcode"
          type="text"
        />
      </div>
      <div
        class="mb-4 flex flex-col sm:flex-row items-center sm:justify-start sm:space-x-4"
      >
        <label
          class="shadow appearance-none border-2 border-gray-500 bg-[#314359] text-white py-2 px-3 mb-2 sm:mb-0 sm:mr-2 w-[150px]"
          for="Country"
          >Country</label
        >
        <input
          class="shadow appearance-none border-2 border-gray-500 bg-[#314359] text-white py-2 px-3"
          id="country"
          v-model="address.country"
          type="text"
        />
      </div>
      <div
        class="mb-4 flex flex-col sm:flex-row items-center sm:justify-start sm:space-x-4"
      >
        <label
          class="shadow appearance-none border-2 border-gray-500 bg-[#314359] text-white py-2 px-3 mb-2 sm:mb-0 sm:mr-2 w-[150px]"
          for="phone"
          >Phone</label
        >
        <input
          class="shadow appearance-none border-2 border-gray-500 bg-[#314359] text-white py-2 px-3"
          id="phone"
          v-model="address.phone"
          type="text"
        />
      </div>
      <div
        class="mb-4 flex flex-col sm:flex-row items-center sm:justify-start sm:space-x-4"
      >
        <label
          class="shadow appearance-none border-2 border-gray-500 bg-[#314359] text-white py-2 px-3 mb-2 sm:mb-0 sm:mr-2 w-[150px]"
          for="vat"
          >VAT</label
        >
        <input
          class="shadow appearance-none border-2 border-gray-500 bg-[#314359] text-white py-2 px-3"
          id="vat"
          v-model="address.vat"
          type="text"
        />
      </div>
      <div class="flex justify-center mt-6 mr-48" :hidden="true">
        <button
          class="bg-[#318bc8] hover:bg-[#318bc8] text-white font-bold py-2 px-4 rounded"
          type="submit"
          :hidden="true"
        >
          Update
        </button>
      </div>
    </form>
    <notifications position="bottom right" group="foo" />
    <div v-if="updated" class="mt-3 text-green-500">
      Your profile has been updated.
    </div>
  </div>
</template>

<script>
import "firebase/compat/auth";
import "firebase/compat/firestore";
import store from "@/store/store";

export default {
  name: "AddressComponent",
  data() {
    return {
      updated: false,
      address: {},
    };
  },
  created() {
    this.address = store.getters.getUser.address
  },
  methods: {
    updateAddress() {
      try {
        // Update the address in the local storage or perform any other necessary actions
        localStorage.setItem("address", JSON.stringify(this.address));

        // Notify the user about the successful update
        this.$notify({
          group: "foo",
          type: "success",
          title: "Success",
          text: "Your address has been updated successfully.",
        });

        // Set the updated flag to true
        this.updated = true;
      } catch (e) {
        // Handle any errors that may occur during the update
        console.error("Error updating address:", e);

        // Notify the user about the error
        this.$notify({
          group: "foo",
          type: "error",
          title: "Error",
          text: "An error occurred while updating your address.",
        });

        // Set the updated flag to false
        this.updated = false;
      }
    },
  },
};
</script>


<style></style>
