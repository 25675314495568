import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VueApexCharts from "vue3-apexcharts";
import PerfectScrollbar from "vue3-perfect-scrollbar";
import PrimeVue from "primevue/config";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
import Notifications from "@kyvg/vue3-notification";
import Toast from "vue3-toastify";
import { createPinia } from "pinia";
import { store } from "./store/store";
import "./assets/tailwind.css";
import "./assets/animate.css";
import "./assets/sass/css/trident.css";
import vClickOutside from "click-outside-vue3";
import { getFirestore } from "firebase/firestore";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

import "leaflet/dist/leaflet.css";

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library, config } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet.markercluster/dist/leaflet.markercluster';

import { LMarkerCluster } from '@vue-leaflet/vue-leaflet';


config.autoAddCss = false;
library.add(fas, far, fab);

//Import Primecss
import "primevue/resources/primevue.min.css";
import "primevue/resources/themes/lara-light-indigo/theme.css";

/* import specific icons */
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";

/* add icons to the library */
library.add(faUserSecret);

// @ts-ignore
import "vue-phone-number-input/dist/vue-phone-number-input.css";

import VueTelInput from "vue-tel-input";
import "vue-tel-input/vue-tel-input.css";

import {apolloProvider} from "@/jwt/apolloclientprovider";
import logout from "@/jwt/auth";
import 'leaflet/dist/leaflet.css';


const app = createApp(App).component('font-awesome-icon', FontAwesomeIcon);
const firebaseConfig = {
  apiKey: "AIzaSyAUosjF7lO_4N3rf6sbCHMeHZRCmBTd6L0",
  authDomain: "trident-afc14.firebaseapp.com",
  projectId: "trident-afc14",
  storageBucket: "trident-afc14.appspot.com",
  messagingSenderId: "629052548864",
  appId: "1:629052548864:web:9b100ccb6765356c5475e5",
  measurementId: "G-RMKQWTELGJ",
};

// Initialize Firebase
const fireApp = firebase.initializeApp(firebaseConfig);

app.component('font-awesome-icon', FontAwesomeIcon)
app.use(router);
app.component('l-marker-cluster', LMarkerCluster)
app.use(createPinia());
app.use(PrimeVue);
app.use(VueApexCharts);
app.use(PerfectScrollbar);
app.use(Notifications);
app.use(Toast);
app.use(vClickOutside);
app.use(VueTelInput);
app.use(store);
app.use(apolloProvider)
app.mount("#app");

// Set authentication state persistence to 'SESSION'
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);

const db = getFirestore(fireApp);

export default db;
